import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import { ToWords } from 'to-words';
import pdfFonts from "pdfmake/build/vfs_fonts";
import numberToText from "number2text";
import telephone from "../dispatchOperation/telephone.json"
import {
  primaryColor,
  drawerTextColor,
} from "../../../constants/internal/colors";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const dashboardStyles = (theme) => ({
  primaryBackground: {
    backgroundColor: primaryColor,
    color: drawerTextColor,
    borderRadius: "30px",
  },
});

class RIPLInvoicePdfDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plantItemDetails: {},
      total_invoice_value: 0.0,
      total_taxable_value: 0.0,
      total_value: 0.0,
      total_cess_value: 0.0,
      total_cgst_value: 0.0,
      total_sgst_value: 0.0,
      total_igst_value: 0.0,
      total_cess_non_advol_value: 0.0,
      total_freight_advance_amount: 0.0,
      total_quantity: 0.0,
      total_tcs_amount: 0.0,
      total_freight_amount: 0.0,
      total_tax_on_amount: 0.0,
      eInvoiveResponse: null,
      eWayBillResponse: null,
      b2CQrImage: null,
      plantAccountDetails: {},
      // laDetailsInDispatchCounter: {},
    };
  }

  componentDidMount() {
    // console.log(this.props.plant, this.props.plantItemDetails[0])
    this.setState({
      plantItemDetails: this.props.plantItemDetails,
      total_invoice_value: this.props.total_invoice_value,
      total_taxable_value: this.props.total_taxable_value,
      total_value: this.props.total_value,
      total_cess_value: this.props.total_cess_value,
      total_cgst_value: this.props.total_cgst_value,
      total_sgst_value: this.props.total_sgst_value,
      total_igst_value: this.props.total_igst_value,
      eInvoiveResponse: this.props.eInvoiveResponse,
      eWayBillResponse: this.props.eWayBillResponse,
      total_cess_non_advol_value: this.props.total_cess_non_advol_value,
      total_freight_advance_amount: this.props.total_freight_advance_amount,
      total_freight_amount: this.props.total_freight_amount,
      total_quantity: this.props.total_quantity,
      total_tcs_amount: this.props.total_tcs_amount,
      total_tax_on_amount: this.props.total_tax_on_amount,
      b2CQrImage: this.props.b2CQrImage,
      plantAccountDetails: this.props.plantAccountDetails,
      item_category_logo: this.props.item_category_logo,
      plant_banner: this.props?.plant_banner,
      // plan: { plant: this?.props?.plant }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.plantItemDetails !== state.plantItemDetails) {
      return { plantItemDetails: props.plantItemDetails };
    }
    if (props.total_invoice_value !== state.total_invoice_value) {
      return { total_invoice_value: props.total_invoice_value };
    }
    if (props.total_taxable_value !== state.total_taxable_value) {
      return { total_taxable_value: props.total_taxable_value };
    }
    if (props.total_value !== state.total_value) {
      return { total_value: props.total_value };
    }
    if (props.total_cess_value !== state.total_cess_value) {
      return { total_cess_value: props.total_cess_value };
    }
    if (props.total_cgst_value !== state.total_cgst_value) {
      return { total_cgst_value: props.total_cgst_value };
    }
    if (props.total_sgst_value !== state.total_sgst_value) {
      return { total_sgst_value: props.total_sgst_value };
    }
    if (props.total_igst_value !== state.total_igst_value) {
      return { total_igst_value: props.total_igst_value };
    }
    if (props.eInvoiveResponse !== state.eInvoiveResponse) {
      return { eInvoiveResponse: props.eInvoiveResponse };
    }
    if (props.eWayBillResponse !== state.eWayBillResponse) {
      return { eWayBillResponse: props.eWayBillResponse };
    }
    if (props.total_cess_non_advol_value !== state.total_cess_non_advol_value) {
      return { total_cess_non_advol_value: props.total_cess_non_advol_value };
    }
    if (
      props.total_freight_advance_amount !== state.total_freight_advance_amount
    ) {
      return {
        total_freight_advance_amount: props.total_freight_advance_amount,
      };
    }
    if (props.total_freight_amount !== state.total_freight_amount) {
      return { total_freight_amount: props.total_freight_amount };
    }
    if (props.total_quantity !== state.total_quantity) {
      return { total_quantity: props.total_quantity };
    }
    if (props.total_tcs_amount !== state.total_tcs_amount) {
      return { total_tcs_amount: props.total_tcs_amount };
    }
    if (props.total_tax_on_amount !== state.total_tax_on_amount) {
      return { total_tax_on_amount: props.total_tax_on_amount };
    }
    if (props.b2CQrImage !== state.b2CQrImage) {
      return { b2CQrImage: props.b2CQrImage };
    }

    if (props.plantAccountDetails !== state.plantAccountDetails) {
      return { plantAccountDetails: props.plantAccountDetails };
    }
    return null;
  }


  numberConvertor = (number) => {
    const toWords = new ToWords();
    let words = toWords.convert(number, { currency: true });
    return words
  }

  // Function to convert logo into BASE64 Code

  getBase64ImageFromURL(ford, url) {
    // console.log(ford, url)
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");
        // console.log(ford, dataURL)
        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  handleViewOrDownloadPdf = async (pdfOperation, copyForText) => {
    let sgstCgstSum = parseFloat(
      Number(this?.state?.total_sgst_value) +
      Number(this?.state?.total_cgst_value)
    ).toFixed(2);
    let tableData = [];
    let roundOffSum = 0;
    let divisionTable = []
    let gstTable = []
    let panTable = []

    let footerContainer = []
    let headerContainer = []
    // console.log(this.props)

    if (this.props.isLogoRequired) {
      headerContainer.push([
        {
          columns: [
            [
              this.props?.plant?.plant_logo &&
              {
                image: await this.getBase64ImageFromURL("plant", this.props?.plant?.plant_logo),
                width: 120,
                height: 50,
                alignment: "left",
                // border: [false, false, false, false],
              },
              {
              text: `Works : ${this.state?.plantItemDetails[0]?.SUPADD1 ? this.state?.plantItemDetails[0]?.SUPADD1 + this.state?.plantItemDetails[0]?.SUPADD2 || "": ""} ${this.state?.plantItemDetails[0]?.SUPCITY ? `, ${this.state?.plantItemDetails[0]?.SUPCITY}`: ""} ${this.props?.plant_state ? `, ${this.props?.plant_state}`: ""} ${this.state?.plantItemDetails[0]?.SUPPINCODE ? `- ${this.state?.plantItemDetails[0]?.SUPPINCODE}`: ""}`,              
                fontSize: 7,
                width: 200,
                margin: [0, 2, 0, 0]
              },
              {
                text: `Ph. No. : ${this.props?.plant?.plant_mobile}`,
                fontSize: 7
              },
              {
                text: `e-mail : ${this.props?.plant?.plant_email}`,
                fontSize: 7
              }
            ],

            {
              text: "TAX INVOICE",
              width: 170,
              fontSize: 8,
              bold: true,
              decoration: "underline",
              // height: 50,
              alignment: "center",
              border: [true, true, true, true],
            },
            {
              columns: [
                [
                  {
                    text: copyForText,
                    width: 290,
                    style: "topRightTextStyle",
                    border: [true, true, true, true],
                    // border: [false, false, false, false],
                  },
                  this.state?.item_category_logo &&
                  {
                    image: await this.getBase64ImageFromURL("item", this.state?.item_category_logo),
                    width: 60,
                    height: 60,
                    alignment: "right",
                    margin: [0, 5, 5, 0],
                    // border: [false, false, false, false]
                  },
                ]
              ]
            },
          ]
        },
        {
          columns: [
            {
              style: 'tableExample',
              table: {
                body: divisionTable,
                widths: [75, 5, 75],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
            {
              style: 'tableExample',
              table: {
                body: gstTable,
                widths: [50, 5, 100],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
            {
              style: 'tableExample',
              table: {
                body: panTable,
                widths: [20, 5, 125],
                // dontBreakRows: true,
              },
              layout: "headerLayout"
              // margin: [0, 15, 0, 0]
            },
          ]
        },
      ])
      footerContainer.push([
        {
          text: "Disclaimer : ",
          style: "disclaimerStyle"
        },
        {
          columns: [
            [

              {
                text: "(1)  We declare that the particulars given above are true and correct.",
                style: "fontSevenLeft",
                width: 310,
                // preserveLeadingSpaces: true
                // border: [true, true, true, true]
              },
              {
                text: "(2)  The amount indicated / represents the price actually charged and that there is not flow of\n\u200B\t\tadditional consideration directly or indirectly from the buyer.",
                style: "fontSevenLeft",
                width: 310,
                preserveLeadingSpaces: true
                // border: [true, true, true, true]
              },

              {
                text: "Notes :",
                style: "disclaimerStyle",
                border: [false, false, false, false],

              },
              {
                text: "(1)  Payment to be strictly on scheduled time, otherwise interest will be charged @18% P.A.",
                style: "fontSevenLeft",
                // border: [false, false, false, false],
                width: 310
              },
              {
                text: "(2)  We are not responsible for any breakage and shortage once the goods leave our \n\u200B\t\tpremises/handed over to Railway or Motor Transport or other carriers.",
                style: "fontSevenLeft",
                // border: [false, false, false, false],
                width: 310
              },
              {
                text: "(3)  Any variation in our supply should bought to our notice within 3 days from the receipt\n\u200B\t\tof the goods.",
                style: "fontSevenLeft",
                // border: [false, false, false, false],
                width: 310
              },
              {
                text: "(4)  Subject to Raipur Jurisdiction.",
                style: "fontSevenLeft",
                // border: [false, false, false, false],
                width: 310
              },
              {
                text: "(5)  Tax Invoice under Section 31 with Rule 46 of CGST Act 2017",
                style: "fontSevenLeft",
                // border: [false, false, false, false],
                width: 310
              },

            ],
            [
              {
                text: `For, ${this.props?.plant?.plant_name}`,
                style: "forSignatureCenter",
                border: [false, false, false, false],
                margin: [0, 25, 0, 0],
                color: "blue",
                width: 300
              },
              {
                text: `Authorized Signature`,
                style: "SignatureCenter",
                border: [false, false, false, false],
                margin: [35, 50, 0, 0],
                width: 300
              },
            ]
          ]
        },
        {
          text: "Bank Details :",
          style: "disclaimerStyle",
          border: [false, false, false, false],
          margin: [0, -8, 0, 0]
        },
        {
          text: `Bank : ${this.props?.plant?.bank_name ? this.props?.plant?.bank_name : ""}`,
          style: "fontSevenLeft",
          // border: [false, false, false, false],
          // bold: true,
        },
        {
          text: `A/C # : ${this.props?.plant?.plant_account_no ? this.props?.plant?.plant_account_no : ""}`,
          style: "fontSevenLeft",
          // border: [false, false, false, false],
          // bold: true,
        },
        {
          text:
            `Branch and IFS Code : ${this.props?.plant?.branch_name ? this.props?.plant?.branch_name : ""} and ${this.props?.plant?.plant_account_ifsc ? this.props?.plant?.plant_account_ifsc : ""}`,
          style: "fontSevenLeft",
          // border: [false, false, false, false],
          // bold: true,
        },
        {
          columns: [
            {
              text: "Corporate Office :",
              style: "fontSevenLeft",
              // border: [true, true, true, true],
              bold: true,
              width: 57
            },
            {
              text: `“Vrindavan”, Near IDBI Bank, Civil Lines, Raipur (C.G.) - 492001`,
              style: "fontSevenLeft",
              // border: [true, true, true, true],
              width: 200

            }
          ],
          width: 300
        },
        {
          margin: [0, 10, 0, 0],
          columns: [
            {
              width: "25%",
              text: "E & OE.",
              style: "OEstyle"
            },
            {
              columns: [
                {
                  width: "16%",
                  text: `Driver / `,
                  style: "OEstyle"
                },
                {
                  image: telephone,
                  width: 9,
                  height: 9,
                  margin: [0, 1.5, 0, 0],
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  width: "2%",
                  text: " "
                },
                {
                  style: "OEValuestyle",
                  width: "52%",
                  text: this?.state?.plantItemDetails[0]?.Driver_Name && this.state?.plantItemDetails[0]?.Driver_Mob_Number ?
                    `${" "} : ${this?.state?.plantItemDetails[0]?.Driver_Name ? this.state?.plantItemDetails[0]?.Driver_Name : ""} / ${this.state?.plantItemDetails[0]?.Driver_Mob_Number ? this.state?.plantItemDetails[0]?.Driver_Mob_Number : ""}`
                    : ""
                }
              ]
            },
            {
              columns: [
                {
                  width: "38%",
                  text: `Prepared/Checked By :`,
                  style: "OEValuestyle"
                },
                // {
                //   image: telephone,
                //   width: 9,
                //   height: 9,
                //   margin: [0, 1.5, 0, 0],
                //   alignment: "left",
                //   border: [false, false, false, false],
                // },
                // {
                //   width: "2%",
                //   text: " "
                // },
                {
                  style: "OEValuestyle",
                  width: "60%",
                  text: ` ${this.state?.plantItemDetails[0]?.PREPARED_BY_NAME ? this.state?.plantItemDetails[0]?.PREPARED_BY_NAME : ""}`,
                }
              ]
            },
            // {
            //   width: "37%",
            //   text: `Prepared By : ${this.state?.plantItemDetails[0]?.PREPARED_BY_NAME ? this.state?.plantItemDetails[0]?.PREPARED_BY_NAME : ""}`,
            //   style: "fontNine"
            // },

          ]
        },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },
        this.state?.plant_banner ?
          {
            image: await this.getBase64ImageFromURL("banner", this.state?.plant_banner),
            width: 500,
            height: 22,
            alignment: "center",
            margin: [0, 2, 0, 2],
            // border: [false, false, false, false]
          } :
          {
            text: "",
            margin: [0, 26, 0, 0]
          },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 565, y2: 0, lineWidth: 0.5 }] },

        {
          text: "This is computer generated invoice",
          alignment: "center",
          fontSize: 7,
          margin: [0, 2, 0, 0]
        }
      ])
    }
    divisionTable.push([
      {
        text: "Range",
        style: "divisionHeader",
        border: [true, true, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, true, false, false],
      },
      {
        text: this.props?.plant?.range ? this.props?.plant?.range : "",
        style: "divisionHeader",
        border: [false, true, true, false],
      },
    ],
      [{
        text: "Division",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.plant?.division ? this.props?.plant?.division : "",
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "Commissionerate",
        style: "divisionHeader",
        border: [true, false, false, true],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: this.props?.plant?.commissionerate ? this.props?.plant?.commissionerate : "",
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)

    gstTable.push([{
      text: "GSTIN",
      style: "divisionHeader",
      border: [true, true, false, false],
    },
    {
      text: ":",
      style: "divisionHeader",
      border: [false, true, false, false],
    },
    {
      text: this.props?.plant?.plant_gst_number ? this.props?.plant?.plant_gst_number : "",
      style: "divisionHeader",
      border: [false, true, true, false],
    },
    ],
      [{
        text: "State Code",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.plant?.plant_gst_number
          ? `${this.props?.plant?.plant_gst_number[0]}${this.props?.plant?.plant_gst_number[1]}` : "",
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "State Name",
        style: "divisionHeader",
        border: [true, false, false, true],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: this.props?.plant_state ? this.props?.plant_state : "",
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)

    panTable.push([{
      text: "Pan",
      style: "divisionHeader",
      border: [true, true, false, false],
    },
    {
      text: ":",
      style: "divisionHeader",
      border: [false, true, false, false],
    },
    {
      text: this.props?.plant?.plant_pan_number ? this.props?.plant?.plant_pan_number : "",
      style: "divisionHeader",
      border: [false, true, true, false],
    },
    ],
      [{
        text: "CIN",
        style: "divisionHeader",
        border: [true, false, false, false],
      },
      {
        text: ":",
        style: "divisionHeader",
        border: [false, false, false, false],
      },
      {
        text: this.props?.plant?.cin ? this.props?.plant?.cin : "",
        style: "divisionHeader",
        border: [false, false, true, false],
      },
      ],
      [{
        text: "Tax is payable on reverse charges : No",
        colSpan: 3,
        style: "divisionHeader",
        border: [true, false, true, true],
      },
      {
        text: "",
        style: "divisionHeader",
        border: [false, false, false, true],
      },
      {
        text: "",
        style: "divisionHeader",
        border: [false, false, true, true],
      },
      ],)


    tableData.push([
      {
        text: "Sr. No.",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "Description and Speci. Of Goods",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "HSN/SAC No.",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "No. Of Packages",
        style: ["itemsHeader", "center"],
        border: [false, false, false, true],
      },
      {
        text: "Total Qty.",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      {
        text: "Unit",
        style: "itemsHeader",
        alignment: "center",
        border: [false, false, false, true],
      },
      {
        text: "Unit Rate (₹)",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      // {
      //   text: "Net Rate (₹)",
      //   style: "itemsHeader",
      //   alignment: "right",
      //   border: [false, false, false, true],
      // },
      {
        text: `Freight Charge ${this?.state?.plantItemDetails[0]?.FREIGHTRATE ? `(${this?.state?.plantItemDetails[0]?.FREIGHTRATE} /MT)` : ""}`,
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
      {
        text: "Amount(Rs.)",
        style: "itemsHeader",
        alignment: "right",
        border: [false, false, false, true],
      },
    ]);
    this.state.plantItemDetails.map((itemDetails) => {
      roundOffSum =
        Number(roundOffSum) + Number(itemDetails?.ROUNDOFFAMOUNT1 || 0);
      tableData.push([
        {
          text: itemDetails?.SERIAL_NO || "",
          style: "itemNumber",
          border: [false, false, false, false],
        },
        {
          text: itemDetails?.PRODUCTNAME || "",
          style: "itemSubTitle",
          border: [false, false, false, false],
        },
        {
          text: itemDetails?.HSNCODE || "",
          style: "itemNumber",
          border: [false, false, false, false],
        },
        {
          text:
            this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
              this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
              ? 0
              : itemDetails?.REMARK || "",
          style: "itemNumber",
          border: [false, false, false, false],
        },
        {
          text: parseFloat(itemDetails?.QUANTITY || 0).toFixed(3),
          style: "itemNumberRightAligned",
          border: [false, false, false, false],
        },
        {
          text: itemDetails?.QTYUNIT || "",
          style: "itemNumber",
          border: [false, false, false, false],
        },
        {
          text: parseFloat(Number(itemDetails?.DISCOUNT_RATE || 0)
            // Number(itemDetails?.RATE || 0) - Number(itemDetails?.DISCOUNT || 0)
          ).toFixed(2),
          style: "itemNumberRightAligned",
          border: [false, false, false, false],
        },
        // {
        //   text: parseFloat(
        //     Number(itemDetails?.NET_RATE || 0)
        //   ).toFixed(2),
        //   style: "itemNumberRightAligned",
        //   border: [false, false, false, false],
        // },
        {
          // text: parseFloat(itemDetails?.TAXONAMOUNT || 0).toFixed(2),
          // text: parseFloat(itemDetails?.taxable_amount).toFixed(2),
          text: parseFloat(Number(itemDetails?.FREIGHTAMOUNT || 0)).toFixed(2),
          style: "itemNumberRightAligned",
          border: [false, false, false, false],
        },
        {
          text: (parseFloat(itemDetails?.TAXONAMOUNT || 0) + parseFloat(itemDetails?.FREIGHTAMOUNT || 0)).toFixed(2),
          style: "itemNumberRightAligned",
          border: [false, false, false, false],
        },
      ]);
    });
    let taxationTableData = [];

    // if (Number(this?.state?.total_freight_amount) !== 0) {
    //   taxationTableData.push([
    //     {
    //       text: "FREIGHT CHARGE :",
    //       border: [false, false, false, false],
    //       style: "fontSevenCenter",
    //       width: "40%",
    //     },
    //     {
    //       border: [false, false, false, false],
    //       text: `${this?.state?.plantItemDetails[0]?.FREIGHTRATE || 0} / MT`,
    //       style: "fontSevenCenter",
    //       width: "30%",
    //     },
    //     {
    //       text: parseFloat(this?.state?.total_freight_amount || 0).toFixed(2),
    //       border: [false, false, false, false],
    //       // style: "fontEightRight",
    //       style: "fontSevenRight",
    //       width: "30%",
    //     },
    //   ]);
    // }
    if (
      Number(this.state.total_sgst_value) > Number(this.state.total_igst_value)
    ) {
      taxationTableData.push([
        {
          text: "SGST :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${this?.state?.plantItemDetails[0]?.SGSTRATE} %`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: this?.state?.total_sgst_value || "",
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
      taxationTableData.push([
        {
          text: "CGST :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${this?.state?.plantItemDetails[0]?.CGSTRATE} %`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: this?.state?.total_cgst_value || "",
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    } else {
      taxationTableData.push([
        {
          text: "IGST :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${this?.state?.plantItemDetails[0]?.IGSTRATE} %`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: this?.state?.total_igst_value,
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }
    if (Number(this?.state?.total_cess_non_advol_value) !== 0) {
      taxationTableData.push([
        {
          text: "CESS :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${this?.state?.plantItemDetails[0]?.CESSRATE || 0} / MT`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: parseFloat(
            this?.state?.total_cess_non_advol_value || 0
          ).toFixed(2),
          // this?.state?.total_cess_value || '',
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }

    if (Number(this?.state?.total_tcs_amount) !== 0) {
      taxationTableData.push([
        {
          text: "TCS :",
          border: [false, false, false, false],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: `${Number(this?.state?.total_tcs_amount) === 0
            ? 0
            : this?.state?.plantItemDetails[0]?.AFRATE12 || 0
            } %`,
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: parseFloat(this?.state?.total_tcs_amount || 0).toFixed(2),
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }

    if (Number(this?.state?.total_freight_advance_amount) !== 0) {
      taxationTableData.push([
        {
          text: "FREIGHT ADVANCE :",
          border: [false, false, false, false],
          style: "fontSevenLeft",
          width: "40%",
        },
        {
          border: [false, false, false, false],
          text: "",
          style: "taxDetailCenter",
          width: "30%",
        },
        {
          text: parseFloat(
            this?.state?.total_freight_advance_amount || 0
          ).toFixed(2),
          border: [false, false, false, false],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }
    if (roundOffSum !== 0) {
      taxationTableData.push([
        {
          text: "ROUND OFF :",
          border: [false, false, false, true],
          style: "fontSevenRight",
          width: "40%",
        },
        {
          border: [false, false, false, true],
          text: "",
          style: "fontSevenCenter",
          width: "30%",
        },
        {
          text: parseFloat(roundOffSum).toFixed(2),
          border: [false, false, false, true],
          // style: "fontEightRight",
          style: "fontSevenRight",
          width: "30%",
        },
      ]);
    }
    let eInvoiceDataTable = [];
    // let b2CInvoiceData = [];
    if (this?.state?.eInvoiveResponse !== null) {
      if (this?.state?.eInvoiveResponse?.Status === "1") {
        eInvoiceDataTable.push([
          {
            text: "E-Invoice",
            style: "invoiceDetailLable",
            fillColor: "#f5f5f5",
            alignment: "center",
            border: [false, false, false, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            image: `data:image/png;base64,${this?.state?.eInvoiveResponse?.SignedQRCode}`,
            width: 115,
            height: 115,
            alignment: "center",
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `IRN : ${this?.state?.eInvoiveResponse?.Irn || ""}`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `IRN DATE : ${this.state.eInvoiveResponse && this.state.eInvoiveResponse.AckDate
              ? moment(
                new Date(this?.state?.eInvoiveResponse?.AckDate)
              ).format("DD-MMM-YYYY")
              : ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: "",
            border: [false, false, false, false],
          },
        ]);
      } else {
        return null;
      }
    } else if (this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C") {
      eInvoiceDataTable.push([
        {
          text: "Invoice",
          style: "invoiceDetailLable",
          fillColor: "#f5f5f5",
          alignment: "center",
          border: [false, false, false, true],
        },
      ]);
      this?.state?.b2CQrImage &&
        eInvoiceDataTable.push([
          this.state.plantItemDetails[0]?.PAYMENT_MODE === "AA" &&
            this.state.plantItemDetails[0]?.PAYMENTAMOUNT
            ? {
              qr: `{
                 "SUPGSTN" : "${this.state.plantItemDetails[0]?.SUPGSTIN || ""
                }", "PAYEE_AC" : ${this.state.plantAccountDetails?.accountNumber || ""
                }, "PAYEE_IFSC" : "${this.state.plantAccountDetails?.ifscCode || ""
                }", ${Number(this?.state?.total_igst_value) > 0
                  ? `"IGST : ${this?.state?.total_igst_value || ""}, `
                  : `"CGST : ${this?.state?.total_cgst_value || ""
                  }, "SGST" : ${this?.state?.total_sgst_value || ""}, `
                } "CESS" :  ${this?.state?.total_cess_value || ""
                }, "TOT_AMT" : ${this?.state?.total_invoice_value || ""
                }, "INV_DATE" : ${moment(
                  this?.state?.plantItemDetails[0]?.DOCDATE,
                  "DD/MM/YYYY"
                ).format("DD MMM YYYY")},
            }`,
              fit: 130,
              width: 130,
              height: 130,
              alignment: "center",
              border: [true, false, true, false],
            }
            : {
              image: this?.state?.b2CQrImage || "",
              width: 130,
              height: 130,
              alignment: "center",
              border: [true, false, true, false],
            },
        ]);
      if (this.state.plantItemDetails[0]?.PAYMENT_MODE === "AA") {
        eInvoiceDataTable.push([
          {
            text: `REFERENCE NO. : ${this.state.plantItemDetails[0]?.REFRENCENUMBER || ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `AMOUNT 
             ${this.state.plantItemDetails[0]?.PAYMENT_MODE === "AA" &&
                (this.state.plantItemDetails[0]?.PAYMENTAMOUNT !== null ||
                  this.state.plantItemDetails[0]?.REFRENCENUMBER !== null)
                ? this?.state?.plantItemDetails[0]?.PAYMENTAMOUNT || 0
                : 0
              }
            `,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);

        eInvoiceDataTable.push([
          {
            text: `PAYMENT DATE : ${this?.state?.plantItemDetails[0]?.REFRENCEDATE !== null
              ? moment(
                new Date(this.state.plantItemDetails[0]?.REFRENCEDATE)
              ).format("DD-MMM-YYYY")
              : ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            alignment: "left",
            border: [true, false, true, true],
          },
        ]);
        eInvoiceDataTable.push([
          {
            text: `MODE OF PAYMENT : ${this.state.plantItemDetails[0]?.PAYMENT_MODE === "OC"
              ? "ON CREDIT"
              : this.state.plantItemDetails[0]?.PAYMENT_MODE === "AA"
                ? "ADVANCE"
                : this.state.plantItemDetails[0]?.PAYMENT_MODE === "LC1"
                  ? "AGAINST LC"
                  : this.state.plantItemDetails[0]?.PAYMENT_MODE === "NP"
                    ? "NEXT DAY PAYMENT"
                    : this.state.plantItemDetails[0]?.PAYMENT_MODE === "CP"
                      ? "CASH PAYMENT"
                      : this.state.plantItemDetails[0]?.PAYMENT_MODE === "PD"
                        ? "AGAINST PDC"
                        : this.state.plantItemDetails[0]?.PAYMENT_MODE === "BG"
                          ? "AGAINST BG"
                          : this.state.plantItemDetails[0]?.PAYMENT_MODE === "SA"
                            ? "S-ADVANCE"
                            : this.state.plantItemDetails[0]?.PAYMENT_MODE === "CH"
                              ? "CHEQUE"
                              : this.state.plantItemDetails[0]?.PAYMENT_MODE === "RT"
                                ? "RTGS"
                                : this.state.plantItemDetails[0]?.PAYMENT_MODE === "NE"
                                  ? "NEFT PAYMENT"
                                  : this.state.plantItemDetails[0]?.PAYMENT_MODE === "LC2"
                                    ? "SIGHT LC"
                                    : this.state.plantItemDetails[0]?.PAYMENT_MODE === "DD"
                                      ? "DD PAYMENT"
                                      : ""
              }`,
            style: "invoiceDetailLable",
            color: "#333333",
            fillColor: "#f5f5f5",
            margin: [5, 0, 0, 0],
            width: 125,
            border: [true, false, true, false],
          },
        ]);
      }
      eInvoiceDataTable.push([
        {
          text: "",
          border: [false, false, false, false],
        },
      ]);
    }
    eInvoiceDataTable.push([
      {
        text: `INVOICE NO. : ${this?.state?.plantItemDetails[0]?.DOCNO || ""}`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: `INVOICE DATE : ${moment(
          this?.state?.plantItemDetails[0]?.DOCDATE,
          "DD/MM/YYYY"
        ).format("DD-MMM-YYYY") || ""
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: `TYPE : ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN"
          ? "CREDIT NOTE"
          : this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
            ? "DEBIT NOTE"
            : "TAX INVOICE"
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: `CATEGORY : ${this?.state?.plantItemDetails[0]?.tran_sup_type === "DEXP"
          ? "DEEMED EXPORT"
          : this?.state?.plantItemDetails[0]?.tran_sup_type === "EXPWOP"
            ? "EXPORT WITHOUT PAYMENT"
            : this?.state?.plantItemDetails[0]?.tran_sup_type === "EXPWP"
              ? "EXPORT WITH PAYMENT"
              : this?.state?.plantItemDetails[0]?.tran_sup_type === "SEZWOP"
                ? "SUPPLY TO SEZ WITHOUT PAYMENT"
                : this?.state?.plantItemDetails[0]?.tran_sup_type === "SEZWP"
                  ? "SUPPLY TO SEZ WITH PAYMENT"
                  : this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C"
                    ? "B2C"
                    : this?.state?.plantItemDetails[0]?.tran_sup_type || ""
          }`,
        style: "invoiceDetailLable",
        color: "#333333",
        fillColor: "#f5f5f5",
        margin: [5, 0, 0, 0],
        width: 125,
        border: [true, false, true, false],
      },
    ]);
    eInvoiceDataTable.push([
      {
        text: "",
        border: [false, false, false, false],
      },
    ]);
    // if (
    //   !this?.state?.eInvoiveResponse ||
    //   this?.state?.plantItemDetails[0]?.tran_sup_type !== "B2C"
    // ) {
    //   eInvoiceDataTable.push([
    //     {
    //       text: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
    //           this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
    //           ? `AGAINST INVOICE NO. : ${this?.state?.plantItemDetails[0]?.PARTYBILLNO}`
    //           : `E-WAY BILL NO. : ${!this.state.eWayBillResponse
    //             ? this?.state?.plantItemDetails[0]?.WAYBILLNO || ""
    //             : this?.state?.eWayBillResponse?.EwbNo
    //               ? this?.state?.eWayBillResponse?.EwbNo
    //               : this?.state?.eWayBillResponse?.EWayBill
    //                 ? this?.state?.eWayBillResponse?.EWayBill
    //                 : this?.state?.plantItemDetails[0]?.WAYBILLNO || ""
    //           }`
    //         }`,
    //       style: "invoiceDetailLable",
    //       color: "#333333",
    //       fillColor: "#f5f5f5",
    //       margin: [5, 0, 0, 0],
    //       width: 125,
    //       border: [true, false, true, false],
    //     },
    //   ]);
    //   eInvoiceDataTable.push([
    //     {
    //       text: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
    //           this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
    //           ? `INVOICE DATE : ${moment(
    //             new Date(this?.state?.plantItemDetails[0]?.PARTYBILLDATE)
    //           ).format("DD-MMM-YYYY") || ""
    //           }`
    //           : `E-WAY BILL DATE : ${!this?.state?.eWayBillResponse
    //             ? this?.state?.plantItemDetails &&
    //               this?.state?.plantItemDetails[0]?.WAYBILL_DATE
    //               ? moment(
    //                 new Date(
    //                   this?.state?.plantItemDetails[0]?.WAYBILL_DATE
    //                 )
    //               ).format("DD-MMM-YYYY")
    //               : ""
    //             : this?.state?.eWayBillResponse?.EwbDt
    //               ? moment(
    //                 this.state.eWayBillResponse.EwbDt,
    //                 "YYYY-MM-DD h:mm:ss"
    //               ).format("DD-MMM-YYYY")
    //               : this.state.eWayBillResponse.Date
    //                 ? moment(
    //                   this.state.eWayBillResponse.Date,
    //                   "DD/MM/YYYY h:mm:ss"
    //                 ).format("DD-MMM-YYYY")
    //                 : this.state.plantItemDetails &&
    //                   this.state.plantItemDetails[0].WAYBILL_DATE
    //                   ? moment(
    //                     new Date(this?.state?.plantItemDetails[0]?.WAYBILL_DATE)
    //                   ).format("DD-MMM-YYYY")
    //                   : ""
    //           }`
    //         }`,
    //       style: "invoiceDetailLable",
    //       color: "#333333",
    //       fillColor: "#f5f5f5",
    //       margin: [5, 0, 0, 0],
    //       width: 125,
    //       border: [true, false, true, false],
    //     },
    //   ]);
    // }
    console.log("printing invoice ....");
    const dd = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      info: {
        title: `${this?.state?.plantItemDetails[0]?.DOCNO
          }_${new Date().toISOString()}`,
        author: "RIPL",
        subject: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN"
          ? "Credit Note "
          : this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
            ? "Debit Note "
            : "Invoice "
          } Document`,
        keywords: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN"
          ? "Credit Note "
          : this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
            ? "Debit Note "
            : "Invoice "
          } Document`,
      },
      background: this.props.isLogoRequired ?
        function (currentPage, pageSize) {
          return {
            // image: AegleBase64,
            // width: 200,
            text: "<",
            absolutePosition: { x: 15, y: pageSize.height / 2 - 4 },
            // margin: [20, 0, 0, 0]
          }
        } :
        function (currentPage, pageSize) {
          return {
            // image: AegleBase64,
            // width: 200,
            text: "<",
            absolutePosition:
              { x: 15, y: pageSize.height / 2 - 5 },
            // margin: [20, 0, 0, 0]
          }
        },
      pageMargins: this.props.isLogoRequired ? [15, 160, 15, 225] : [15, 170, 15, 30],

      header: {
        stack: headerContainer,
        margin: [15, 25, 15, 0]
      },
      footer: {
        stack: footerContainer,
        margin: [15, 0, 0, 0]

      },
      content: [
        // Top margin for pre-printed invoice data
        // "\n\n\n\n\n\n\n\n\n\n\n\n", //removed one specing unit for invoice print change
        {
          columns: [
            {
              style: "tableExample",
              table: {
                headerRows: 1,
                widths: [50, 50, 50, 50],
                body: [
                  [
                    {
                      text: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? "Details of Recipient (Billed to)"
                        : "Bill To"
                        }`,
                      style: "invoiceDetailLable",
                      colSpan: 4,
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: this?.state?.plantItemDetails[0]?.RECNAME || "",
                      style: "buyerName",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `${this?.state?.plantItemDetails[0]?.RECADD1 || ""
                        }, ${this?.state?.plantItemDetails[0]?.RECADD2 || ""}, ${this?.state?.plantItemDetails[0]?.RECCITY || ""
                        }, ${this?.state?.plantItemDetails[0]?.RECSTATE || ""}, ${this?.state?.plantItemDetails[0]?.RECPINCODE || ""
                        }`,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `STATE CODE: ${this?.state?.plantItemDetails[0]?.RECSTATECODE || ""
                        }`,
                      style: "buyerAddressDetails",
                      colSpan: 2,
                      alignment: "left",
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: `GSTIN: ${this?.state?.plantItemDetails[0]?.bill_to_gstin || ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `PAN NO: ${this?.state?.plantItemDetails[0]?.RECPAN || ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: "MOBILE NO: ",
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `Email : `,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, true],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      colSpan: 4,
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? "Details of Supplier"
                        : "Consignee (Ship To)"
                        }`,
                      style: "invoiceDetailLable",
                      colSpan: 4,
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.SUPNAME || ""
                          : this?.state?.plantItemDetails[0]?.CONSIGNEENAME ||
                          "",
                      style: "buyerName",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? this?.state?.plantItemDetails[0]?.SUPADD1 || ""
                        : this?.state?.plantItemDetails[0]?.CONSIGNEEADD1 ||
                        ""
                        }, ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.SUPADD2 || ""
                          : this?.state?.plantItemDetails[0]?.CONSIGNEEADD2 ||
                          ""
                        }, ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.SUPCITY || ""
                          : this?.state?.plantItemDetails[0]?.CONSIGNEECITY ||
                          ""
                        }, ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.STATE || ""
                          : this?.state?.plantItemDetails[0]?.CONSIGNEESTATE ||
                          ""
                        }, ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.SUPPINCODE || ""
                          : this?.state?.plantItemDetails[0]
                            ?.CONSIGNEEPINCODE || ""
                        }`,

                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: `STATE CODE: ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? this?.state?.plantItemDetails[0]?.SUPSTATE || ""
                        : this?.state?.plantItemDetails[0]?.SHIPTOSTATECODE ||
                        ""
                        }`,
                      style: "buyerAddressDetails",
                      colSpan: 2,
                      alignment: "left",
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: `GSTIN: ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? this?.state?.plantItemDetails[0]?.SUPGSTIN || ""
                        : this?.state?.plantItemDetails[0]?.CONSIGNEEGSTIN ||
                        ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `PAN NO: ${this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                        ? this?.state?.plantItemDetails[0]?.SUPPAN || ""
                        : this?.state?.plantItemDetails[0]?.CONSIGNEEPAN || ""
                        }`,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [true, false, false, false],
                    },
                    {},
                    {
                      text: "MOBILE NO: ",
                      style: "buyerAddressDetails",
                      alignment: "left",
                      colSpan: 2,
                      border: [false, false, true, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: `Email : `,
                      style: "buyerAddressDetails",
                      colSpan: 4,
                      alignment: "left",
                      border: [true, false, true, true],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "",
                      colSpan: 4,
                      border: [false, false, false, false],
                    },
                    {},
                    {},
                    {},
                  ],
                  [
                    {
                      text: "ORDER NO. :",
                      style: "buyerAddressDetails",
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      border: [true, true, false, true],
                      colSpan: 2,
                    },
                    {},
                    {
                      text: this?.state?.plantItemDetails[0]?.ORDER_VRNO,
                      style: "buyerAddressDetails",
                      alignment: "left",
                      fillColor: "#f5f5f5",
                      color: "#333333",
                      border: [true, true, false, true],
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "ORDER DATE :",
                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                    {
                      text:
                        this.state.plantItemDetails &&
                          this.state.plantItemDetails[0].ORDER_VRDATE
                          ? moment(
                            new Date(
                              this?.state?.plantItemDetails[0]?.ORDER_VRDATE
                            )
                          ).format("DD-MMM-YYYY")
                          : "",
                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      color: "#333333",

                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "LA NO :",
                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                    {
                      text:
                        this.state.plantItemDetails &&
                          this.state.plantItemDetails[0].LANO
                          ?
                          this.state.plantItemDetails[0].LANO
                          : "",

                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      color: "#333333",

                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "LA DATE :",
                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                    {
                      text:
                        this.state.plantItemDetails &&
                          this.state.plantItemDetails[0].LADATE
                          ? moment(
                            new Date(
                              this.state.plantItemDetails[0].LADATE
                            )
                          ).format("DD-MMM-YYYY")
                          : "",

                      style: "buyerAddressDetails",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                      color: "#333333",

                      alignment: "left",
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
            {
              margin: [10, 5, 0, 0],
              table: {
                headerRows: 0,
                widths: [75, 75],
                body: [
                  [
                    {
                      text: "TRUCK NO :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.VEHICLENO || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.R. NO. :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.TRANSDOCNO || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.R. DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this.state.plantItemDetails &&
                          this.state.plantItemDetails[0].TRANSDOCDATE
                          ? moment(
                            new Date(
                              this?.state?.plantItemDetails[0]?.TRANSDOCDATE
                            )
                          ).format("DD-MMM-YYYY")
                          : "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "TRANSPORTER NAME :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.TRANSPORTERNAME || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],

                  [
                    {
                      text: "MODE OF TRANSPORT :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.MODEOFSUPPLY || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "FRIEGHT",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.FREIGHT_BASIS ===
                          "FOR-CPT"
                          ? "PREPAID"
                          : this?.state?.plantItemDetails[0]?.FREIGHT_BASIS ||
                          "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: "FROM :",
                      style: "invoiceDetailLable",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.SUPCITY || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "TO :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.TO_PLACE || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      fillColor: "#f5f5f5",
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: "PLACE OF SUPPLY :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.RECCITY || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],
                  [
                    {
                      text: "AGENT NAME : ",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: this?.state?.plantItemDetails[0]?.BROKERNAME || "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "PARTY REF NO :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.REF1_VRNO
                          : this?.state?.plantItemDetails[0]?.PARTY_REF_NO ||
                          "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "PARTY REF DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text:
                        this?.state?.plantItemDetails[0]?.DOCTYPE === "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this.state.plantItemDetails &&
                            this.state.plantItemDetails[0].REF1_VRDATE
                            ? moment(
                              new Date(
                                this?.state?.plantItemDetails[0]?.REF1_VRDATE
                              )
                            ).format("DD-MMM-YYYY")
                            : ""
                          : this.state.plantItemDetails &&
                            this.state.plantItemDetails[0].PARTY_REF_DATE
                            ? moment(
                              new Date(
                                this?.state?.plantItemDetails[0]?.PARTY_REF_DATE
                              )
                            ).format("DD-MMM-YYYY")
                            : "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.C. NO. :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "L.C. DATE :",
                      style: "invoiceDetailLable",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                    {
                      text: "",
                      style: "invoiceDetailLable",
                      color: "#333333",
                      border: [false, false, false, false],
                      fillColor: "#f5f5f5",
                    },
                  ],
                  [
                    {
                      text: "",
                      colSpan: 2,
                      border: [false, false, false, false],
                    },
                    {},
                  ],
                  [
                    this?.state?.eInvoiveResponse !== null ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C" ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2B"
                      ? {
                        text: `${this?.state?.plantItemDetails[0]?.DOCTYPE ===
                          "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? "AGAINST INVOICE NO. :"
                          : "E-WAY BILL NO. :"
                          }`,
                        style: "invoiceDetailLable",
                        fillColor: "#f5f5f5",
                        border: [false, false, false, false],
                      }
                      : "",
                    this?.state?.eInvoiveResponse !== null ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C" ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2B"
                      ? {
                        text: `${this?.state?.plantItemDetails[0]?.DOCTYPE ===
                          "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? this?.state?.plantItemDetails[0]?.PARTYBILLNO
                          : !this.state.eWayBillResponse
                            ? this?.state?.plantItemDetails[0]?.WAYBILLNO ||
                            ""
                            : this?.state?.eWayBillResponse?.EwbNo
                              ? this?.state?.eWayBillResponse?.EwbNo
                              : this?.state?.eWayBillResponse?.EWayBill
                                ? this?.state?.eWayBillResponse?.EWayBill
                                : this?.state?.plantItemDetails[0]?.WAYBILLNO ||
                                ""
                          }`,
                        style: "invoiceDetailLable",
                        color: "#333333",
                        fillColor: "#f5f5f5",
                        border: [false, false, false, false],
                      }
                      : "",
                  ],
                  [
                    this?.state?.eInvoiveResponse !== null ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C" ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2B"

                      ? {
                        text: `${this?.state?.plantItemDetails[0]?.DOCTYPE ===
                          "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? "INVOICE DATE :"
                          : "E-WAY BILL DATE :"
                          }`,
                        style: "invoiceDetailLable",
                        border: [false, false, false, false],
                        fillColor: "#f5f5f5",
                      }
                      : "",
                    this?.state?.eInvoiveResponse !== null ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2C" ||
                      this?.state?.plantItemDetails[0]?.tran_sup_type === "B2B"
                      ? {
                        text: `${this?.state?.plantItemDetails[0]?.DOCTYPE ===
                          "CRN" ||
                          this?.state?.plantItemDetails[0]?.DOCTYPE === "DBN"
                          ? moment(
                            new Date(
                              this?.state?.plantItemDetails[0]?.PARTYBILLDATE
                            )
                          ).format("DD-MMM-YYYY") || ""
                          : !this?.state?.eWayBillResponse
                            ? this?.state?.plantItemDetails &&
                              this?.state?.plantItemDetails[0]?.WAYBILL_DATE
                              ? moment(
                                new Date(
                                  this?.state?.plantItemDetails[0]?.WAYBILL_DATE
                                )
                              ).format("DD-MMM-YYYY")
                              : ""
                            : this?.state?.eWayBillResponse?.EwbDt
                              ? moment(
                                this.state.eWayBillResponse.EwbDt,
                                "YYYY-MM-DD h:mm:ss"
                              ).format("DD-MMM-YYYY")
                              : this.state.eWayBillResponse.Date
                                ? moment(
                                  this.state.eWayBillResponse.Date,
                                  ["DD/MM/YYYY h:mm:ss", "YYYY-MM-DD h:mm:ss"]
                                ).format("DD-MMM-YYYY")
                                : this.state.plantItemDetails &&
                                  this.state.plantItemDetails[0].WAYBILL_DATE
                                  ? moment(
                                    new Date(
                                      this?.state?.plantItemDetails[0]?.WAYBILL_DATE
                                    )
                                  ).format("DD-MMM-YYYY")
                                  : ""
                          }`,
                        style: "invoiceDetailLable",
                        color: "#333333",
                        fillColor: "#f5f5f5",
                        border: [false, false, false, false],
                      }
                      : "",
                  ],

                ],
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
            {
              // style: 'tableExample',
              margin: [-45, 5, 0, 0],
              table: {
                headerRows: 1,
                widths: [115],
                body: eInvoiceDataTable,
              },
              layout: {
                hLineColor: function (i, node) {
                  return "#f5f5f5";
                },
                vLineColor: function (i, node) {
                  return "#f5f5f5";
                },
              },
            },
          ],
        },
        // Items Data
        {
          margin: [5, 0, 0, 0],
          table: {
            headerRows: 1,
            widths: [25, 130, 45, 55, 35, 35, 45, 55, 45],
            body: tableData,
            // margin: [10, 0, 0, 0]
          },
        },
        {
          margin: [5, 0, 0, 0],
          table: {
            headerRows: 0,
            widths: [50, 223, 35, 143, 55],
            body: [
              [
                {
                  text: "Amount in words :",
                  style: "fontSixLeft",
                  fillColor: "#f5f5f5",
                  border: [true, true, false, true],
                },
                {
                  text: `${this.numberConvertor(
                    (Number(this?.state?.total_tax_on_amount || 0) + Number(this.state?.total_freight_amount || 0)).toFixed(2),
                    "",
                    true
                  )}`,
                  style: "fontSixLeft",
                  border: [false, true, false, true],
                  margin: [-8, 0, 0, 0],
                  fillColor: "#f5f5f5",
                },
                {
                  text: this?.state?.total_quantity || 0,
                  border: [false, true, false, true],
                  // style: "fontEightLeft",
                  style: "itemNumberRightAligned",
                  fillColor: "#f5f5f5",
                },
                {
                  border: [false, true, false, true],
                  text: "Total Value of Supply :",
                  // style: "fontEightLeft",
                  style: "fontSevenRight",
                  fillColor: "#f5f5f5",
                },
                {
                  border: [false, true, true, true],
                  text: (Number(this?.state?.total_tax_on_amount || 0) + Number(this.state?.total_freight_amount || 0)).toFixed(2),
                  // style: "fontEightRight",
                  style: "fontSevenRight",
                  fillColor: "#f5f5f5",
                },
              ],
            ],
          },
        },
        {
          margin: [5, 0, 5, 0],
          columns: [
            {
              text: `Total Tax Value (in words) : ${this?.state?.total_sgst_value > this?.state?.total_igst_value
                ? this.numberConvertor(Number(sgstCgstSum), "", true)
                : this.numberConvertor(this?.state?.total_igst_value, "", true)
                }.`,
              // style: "fontEightLeft",
              style: "fontSixLeft",
              margin: [0, 5, 10, 5],
            },
            {
              margin: [0, 0, 5, 0],
              table: {
                headerRows: 0,
                widths: [65, "*", 60],
                body: taxationTableData,
              },
            },
          ],
        },
        {
          margin: [5, 0, 0, 0],
          columns: [
            {
              text: `REMARK : ${this?.state?.plantItemDetails[0]?.IRFIELD6 || ""
                } ${copyForText !== "DUPLICATE FOR TRANSPORTER" ?
                  `\n` + 'PAYMENT TERMS : ' + `${this?.state?.plantItemDetails[0]?.PAYMENT_MODE || ""
                  }`
                  : ""}`,
              // PAYMENT TERMS : ${this?.state?.plantItemDetails[0]?.PAYMENT_MODE || ""
              // }`,
              // style: "fontEightLeft",
              style: "fontSixLeft",
              margin: [0, 0, 0, 5],
            },
          ],
        },
        {
          margin: [5, 0, 0, 0],
          table: {
            headerRows: 0,
            widths: [70, 335, 55, 55],
            body: [
              [
                {
                  text: "Total Amount in words :",
                  border: [true, true, false, true],
                  bold: true,
                  style: "fontSixLeft",
                  fillColor: "#f5f5f5",
                },
                {
                  border: [false, true, false, true],
                  text: `${this.numberConvertor(
                    this.state.total_invoice_value,
                    "",
                    true
                  )}`,
                  style: "fontSixLeft",
                  margin: [-13, 0, 0, 0],
                  fillColor: "#f5f5f5",
                },
                {
                  border: [true, true, false, true],
                  text: "Total Amount ₹",
                  // style: "fontEightLeft",
                  style: "fontSevenLeft",
                  // fontSize: 7,
                  fillColor: "#f5f5f5",
                },
                {
                  border: [false, true, true, true],
                  text: this?.state?.total_invoice_value || "",
                  // fontSize: 7,
                  // style: "fontEightRight",
                  style: "fontSevenRight",
                  fillColor: "#f5f5f5",
                },
              ],
            ],
          },
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 5, 0, 5],
        },
        topRightTextStyle: {
          // width: 150,
          fontSize: 6,
          // height: 50,
          margin: [0, 0, 10, 0],
          alignment: "right",
        },
        OEstyle: {
          fontSize: 10
        },
        OEValuestyle: {
          fontSize: 8,
          margin: [0, 1, 0, 0],
        },
        fontNine: {
          fontSize: 9
        },
        forSignatureCenter: {
          alignment: "center",
          fontSize: 11
        },
        SignatureCenter: {
          alignment: "center",
          fontSize: 9
        },
        disclaimerStyle: {
          alignment: "left",
          fontSize: 7,
          bold: true,
          // margin: [0, 5, 0, 0],
        },
        divisionHeader: {
          fontSize: 8,
          alignment: "left",
        },
        headerLayout: {
          hLineWidth: 0.5,
          vLineWidth: function (i, node) {
            return 0.5;
          },
        },
        // Taxation Data
        fontSixLeft: {
          alignment: "left",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        fontSixRight: {
          alignment: "right",
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        fontSixCenter: {
          alignment: "center",
          fontSize: 6,
          // margin: [0, 3, 0, 3],
        },
        taxDetailCenter: {
          alignment: "center",
          fontSize: 7,
        },
        fontSevenLeft: {
          alignment: "left",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        fontSevenRight: {
          alignment: "right",
          fontSize: 7,
          margin: [0, 0, 0, 0],
        },
        fontSevenCenter: {
          alignment: "center",
          fontSize: 7,
          // margin: [0, 3, 0, 3],
        },
        fontEightRight: {
          alignment: "right",
          fontSize: 8,
          margin: [0, 0, 0, 0],
          bold: true,
        },
        fontEightLeft: {
          alignment: "left",
          fontSize: 8,
          bold: true,
          margin: [0, 0, 0, 0],
        },
        buyerName: {
          bold: true,
          color: "#333333",
          // fontSize: 8,
          fontSize: 7,
          alignment: "left",
          margin: [0, 0, 0, 3],
        },
        buyerAddressDetails: {
          // fontSize: 7,
          fontSize: 6,
          alignment: "left",
        },
        // Invoice Detail
        invoiceDetailLable: {
          color: "#333333",
          // fontSize: 7,
          fontSize: 6,
          alignment: "left",
        },
        itemsHeader: {
          // padding: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 7,
          bold: true,
          fillColor: "#f5f5f5",
        },
        itemSubTitle: {
          // margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 7,
          alignment: "left",
        },
        itemNumber: {
          // margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 7,
          alignment: "center",
        },
        itemNumberRightAligned: {
          // margin: [0, 2, 0, 2],
          // fontSize: 8,
          fontSize: 6.5,
          alignment: "right",
        },
        itemRowEvenNumber: {
          // margin: [0, 2, 0, 2],
          fontSize: 8,
          alignment: "center",
        },
      },
    };

    if (pdfOperation === "VIEW") {
      pdfMake.createPdf(dd).open();
    } else if (pdfOperation === "DOWNLOAD") {
      pdfMake
        .createPdf(dd)
        .download(
          `${this?.state?.plantItemDetails[0]?.DOCNO
          }_${new Date().toISOString()}.pdf`
        );
    } else {
      console.log("invalid pdf operation");
    }
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props)
    // console.log(this.state.laDetailsInDispatchCounter)
    return (
      <Button
        className={classes.primaryBackground}
        variant="outlined"
        sx={{ textTransform: "none" }}
        style={{ width: this.props.pdfOperation === "VIEW" ? "25%" : "55%", height: "24px" }}
        size="small"
        // startIcon={<PictureAsPdfOutlinedIcon />}
        onClick={() => {
          if (this.props.isLogoRequired) {
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "ORIGINAL FOR RECIPIENT")
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "DUPLICATE FOR TRANSPORTER")
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "TRIPLICATE FOR SUPPLIER")
          } else if (this.props.pdfOperation === "EMAIL") {
            this.handleViewOrDownloadPdf(this.props.pdfOperation, "ORIGINAL FOR RECIPIENT")
          } else {
            this.handleViewOrDownloadPdf(this.props.pdfOperation)
          }
        }}
      >
        <span style={{ fontSize: "0.8em" }}>
          {this.props.pdfOperation}
        </span>
      </Button>
    );
  }
}

export default withStyles(dashboardStyles)(RIPLInvoicePdfDownload);
