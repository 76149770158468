import DateFnsUtils from "@date-io/date-fns";
import {
    Button,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    createMuiTheme
} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Cancel from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider, styled } from "@material-ui/styles";
import Box from "@mui/material/Box";
import "date-fns";
import { Form, Formik } from "formik";
import { isEmpty, stubFalse } from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import COLORS, {
    drawerBackground,
    drawerTextColor,
    earthBlue,
    grey,
    lightGreyColor,
    secondaryBlueText,
    secondaryGrey,
    whiteSmoke
} from "../../../../../constants/internal/colors";
import { arraySortBykeyAllBrowser, calculatePercent, checkInvalidValue, pxToEm, checkApiError } from '../../../../../methods';
// import Lock from "@material-ui/icons/Lock";
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { Check, InfoOutlined } from "@material-ui/icons";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { default as Moment, default as moment } from "moment";
import APIROUTES from "../../../../../constants/internal/InternalApiRoutes";
import INTERNALROUTES from "../../../../../constants/internal/InternalRoutes";
import Layout from "../../../../../layouts/internalLayout/InternalLayout";
import Bread from "../../../common/Breadcrubs";
import SnackbarComponent from "../../../common/SnackbarComponent";
import SpinLoader from "../../../../common/SpinLoader";
import ToolTipComponent from "../../../common/ToolTipComponent";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import PresentToAllOutlinedIcon from "@material-ui/icons/PresentToAllOutlined";
import HOST from "../../../../../constants/common/host";

const useStyles = makeStyles((theme) => ({
    stepperVertical: {
        '& .MuiStepConnector-lineVertical': {
            opacity: 0
        },

        '& .MuiStepContent-root': {
            borderLeft: 0
        }
    },
    formControl: {
        margin: 5,
        width: "100%",
    },
    table: {
        minWidth: 650,
    },
    saveButton: {
        backgroundColor: "#4B87C5",
        color: drawerTextColor,
        marginRight: 10,
    },
    backButton: {
        color: "#4B87C5",
    },
    formControl3: {
        // margin: theme.spacing(1),
        width: "30%",
    },
    rightPadding: { paddingRight: "25px" },
    arrowDimension: {
        height: 22,
        width: 22,
        margin: "0px 0px 0px 5px",
        color: secondaryGrey,
        cursor: "pointer",
    },
    arrowDimension2: {
        height: 20,
        width: 20,
        margin: "0px 0px 0px 5px",
        color: secondaryGrey,
        cursor: "pointer",
    },
    formControl4: {
        paddingLeft: "15px",
    },
    contactTextFieldContainer: {
        padding: "5px 15px 20px",
        width: "100%",
        display: "flex",

        justifyContent: "space-between",
    },
    paperStyle: {
        width: "100%",
        // height: "calc(100vh - 120px)",
        height: '80vh',
        display: "flex",
        marginRight: "25px",
        padding: "20px",
        overflowX: "auto",
    },
    addNewButton: {
        width: "181px",
        height: "43px",
        border: `1px dashed ${secondaryGrey}`,
        borderRadius: "4px",
        opacity: "1",
        color: grey,
        fontSize: pxToEm(13),
        fontWeight: "bold",
    },
    addAndSaveButton: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px 20px 20px",
        justifyContent: "space-between",
    },
    titleLabel: {
        fontSize: pxToEm(15),
        color: grey,
        fontWeight: "bold",
        width: "50%",
    },
    mainDiv: {
        width: "100%",
        //height: "100%",
        paddingBottom: "30px",
        //overflowY: "scroll",
    },
    spinLoaderContiner: {
        width: "30%",
        paddingTop: 20,
        marginTop: "20px",
        height: "20vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    customerInfoContainer: {
        width: "32%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "5px"
    },
    customerInfoContainerCenter: {
        margin: "0px 30px",
        width: "32%",
        display: "flex",
        flexDirection: "column",
    },
    noDataDiv: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        display: "flex",
        // height: "calc(100vh - 250px)",
        height: '80vh'
    },

    checkBoxLabelText: {
        color: secondaryGrey,
        fontWeight: "bold",
        marginRight: "6px",
    },
    clientApprovalDiv: { display: "flex", alignItems: "center", width: "65%" },
    discountContainerStyle: {
        display: "flex",
        paddingTop: "20px",
        paddingBottom: "30px",
    },
    subContractLeftContainer: {
        width: "100%",
        height: "75px",
        display: "flex",
        alignItems: "center",
        fontSize: "0.9 em",
        color: lightGreyColor,
    },
    discountContainerStyleLeft: {
        width: "50%",
    },
    discountContainerStyleRight: {
        width: "50%",
    },
    discountHeaderDiv: {
        padding: "10px 0px 10px 22px",
        background: whiteSmoke,
        width: "80%",
        marginBottom: "20px",
    },
    helperText: {
        position: 'absolute',
        top: pxToEm(72)
    },
    discountTextStyle: {
        fontWeight: "bold",
        color: grey,
    },
    discountListStyleDiv: {
        display: "flex",
        width: "90%",
        marginBottom: "18px",
        alignItems: "center",
    },
    tableHeader: {
        backgroundColor: whiteSmoke,
        color: grey,
        fontFamily: 'Poppins',
    },
    tableHead: {
        fontFamily: 'Poppins',
        fontSize: pxToEm(13),
        fontWeight: "bold",
        color: grey,
    },
    contractDetailsContainer: {
        width: "100%",
        fontSize: "12.8px",
        minHeight: "70px",
        border: "1px solid grey",
        borderRadius: "3px"
    },
    additionalDataDisplay: {
        display: "flex",
        flexDirection: "column",
        width: "48%",
    },
    contractDetails: {
        width: "100%",
        height: "100%",
        paddingLeft: "20px",
        marginTop: "10px"
    },
    tableBody: {
        fontSize: pxToEm(13),
        fontFamily: 'Poppins',
    },
    discountListStyleText: {
        width: "50%",
        color: grey,
        fontWeight: "bold",
    },
    discountListStyleValue: {
        width: "50%",
    },
    container: {
        width: "32%",
    },
    width45: {
        width: "45%",
    },
    createLoadingAdviceMainDiv: { width: "100%", placeItems: "center" },
    alignText: {
        display: "flex",
        flexWrap: "wrap",
        placeContent: "center",
    },
    alignTextPopper: {
        display: "flex",
        flexWrap: "wrap"
    },
    rightSpacing: {
        //marginRight: 5,
    },
    generalDetailsDisplaySection: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "20px",
        justifyContent: "space-between",
        width: "100%",
    },
    contractDisplaySection: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
    },
    subContractContainer: {
        width: "100%",
        height: "75px",
        border: "1px solid grey",
        borderRadius: "3px"
    },
    dataDisplay: { display: "flex", flexDirection: "column", width: "48%" },
    displayFlex: {
        padding: "30px 30px 0px 10px",
    },
    dataFieldsForColumn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 0px 10px 0px",
        flexDirection: "column",
    },
    dataFields: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 0px 10px 0px",
    },
    navigationBar: {
        display: "flex",
        alignItems: "center",
        // height: "30px",
        width: "100%",
    },
    headerData: {
        color: "#0000008A",
        marginLeft: "10px",
        fontSize: "16px"
    },
    paperDiv: {
        paddingTop: "20px"
    },
    stepIconRoot: {
        "&.MuiStepIcon-completed": {
            color: "#4B87C5",
        },
        "&.MuiStepIcon-active": {
            color: "#4B87C5",
        },
        "&.MuiStepIcon-disabled": {
            color: "grey",
        },
    },
    formControlPanImage: {
        width: "65%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    panImageUpload: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        placeContent: "center",
        border: "1px solid black",
        borderRadius: "3px",
        width: "150px",
        cursor: "pointer",
        transform: 'scale(0.8)'
    },
}));
const materialTheme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "0.5em",
                color: "yellow",
                backgroundColor: "red"
            }
        }
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: secondaryBlueText,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiPickersDay: {
            day: {
                color: earthBlue,
            },
            daySelected: {
                backgroundColor: secondaryBlueText,
            },
            dayDisabled: {
                color: secondaryGrey,
            },
            current: {
                color: drawerBackground,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: earthBlue,
            },
        },
    },
});

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: "10px 5px 10px 5px",
            },
        },
    },
});

const StyledStepLabel = styled(StepLabel)({
    "& .MuiStepLabel-active": {
        color: "#4B87C5",
        fontWeight: "bold",
    },
});

const ITEM_HEIGHT = 45;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
};

export default function AddSubContractsWebNew(props) {
    const classes = useStyles();
    const ADDED = "ADDED"
    const UPDATED = "UPDATED"
    const DELETED = "DELETED"
    const UNCHANGE = "UNCHANGE"
    const { id, mode } = useParams();
    const { isMobile } = props;
    const { name, plantId, subcontractAvailableQty, subContractDetails, subContractDiscounts } = props.location.state;
    const [errors, setErrors] = React.useState({});
    const [sgstRate, setSgstRate] = React.useState(0);
    const [cgstRate, setCgstRate] = React.useState(0);
    const [igstRate, setIgstRate] = React.useState(0);
    const [allowGstOption, setAllowGstOption] = React.useState(null);
    const [gstOption, setGstOption] = React.useState(null);
    const [orderDetails, setOrderDetails] = React.useState(props.location?.state?.orderDetails)
    const oldAvailableQuantity = React.useRef(subcontractAvailableQty);
    const oldSubcontractQuantity = React.useRef(0);
    const [newAvailableQuantity, setNewAvailableQuantity] = React.useState(subcontractAvailableQty);
    const [originalQuantity, setOriginalQuantity] = React.useState(subcontractAvailableQty);  //to be changed
    const [avaliableQty, setAvaliableQty] = React.useState(subcontractAvailableQty);
    const [snackbarData, setSnackbarData] = React.useState({
        openSnackbar: false,
        snackbarMessage: ``,
        snackbarType: "",
    });
    const [partyRefUpload, setPartyRefUpload] = React.useState({
        partyRefImagePreview: "",
        partyRefRawUploadedFile: "",
        partyRefFileName: null,
        partyRefUploadFileName: null,
        partyRefFileTypeError: '',
    })
    const [confirmationDialogOpen, setConfirmationDialogOpen] =
        React.useState(false);
    const [items, setItems] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [subcontractData, setSubcontractData] = React.useState({ freight_advance: 0, freight_charge_rate: 0, cess_rate: 0 });
    const [buyerSelectionList, setBuyerSelectionList] = React.useState([]);
    const [plantItemOperation, setPlantItemOperation] = React.useState({});
    const [allItemsList, setAllItemsList] = React.useState([]);
    const [allItemsListMaster, setAllItemsListMaster] = React.useState([]);
    const [itemDetailsData, setItemDetailsData] = React.useState([]);
    const [itemDetailsDataOriginal, setItemDetailsDataOriginal] = React.useState([]);
    const [receivedSubContractItems, setReceivedSubContractItems] = React.useState([])
    const [itemData, setItemData] = useState({});
    const [allConsigneeList, setAllConsigneeList] = useState([]);
    const [hasItemError, setHasItemError] = useState(false);
    const [hasAllottedQtyErr, setHasAllottedQtyErr] = useState(false);
    const [allottedQtyErrMsg, setAllottedQtyErrMsg] = useState("");
    const [itemErrorMessage, setItemErrorMessage] = useState("")
    const [additionalData, setAdditionalData] = useState({});
    const [consigneeData, setConsigneeData] = useState({});
    const [bankMasterList, setBankMasterList] = useState([]);
    const [discountData, setDiscountData] = useState({});
    const [categoryCityDiscountData, setCategoryCityDiscountData] = useState([]);
    const [categoryPaymentMethodDiscountData, setCategoryPaymentMethodDiscountData] = useState([]);
    const [cityPincodeData, setCityPincodeData] = useState({});
    const [buyerPinCode, setBuyerPinCode] = useState(null)
    const [cityDiscount, setCityDiscount] = useState(0);
    const [paymentMethodDiscount, setPaymentMethodDiscount] = useState(0);
    const [paymentBasisDiscount, setPaymentBasisDiscount] = useState(0);
    const [AllPaymentTerms, setAllPaymentTerms] = useState([]);
    const [freightBasis, setFreightBasis] = useState([]);
    const [tagsMaster, setTagsMaster] = useState([]);
    const [allPlantsData, setAllPlantsData] = useState([]);
    const [itemCategoryData, setItemCategoryData] = useState({});
    const [tcsRate, setTcsRate] = useState({});
    const [selectedItemDetailsData, setSelectedItemDetailsData] = useState({})
    const [isSubcontractQtyValid, setIsSubcontractQtyValid] = useState(true);
    const [isTotalValueValid, setIsTotalValueValid] = useState(false);
    const [isTotalItemValueValid, setIsTotalItemValueValid] = useState(false);
    const [allottedQtyValid, setAllottedQtyValid] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [discountInitialData] = useState({
        city_discount: subContractDiscounts?.find(d => d?.discount_type == 'CITY')?.discount_amount || 0,
        payment_discount: subContractDiscounts?.find(d => d?.discount_type == 'PAYMENT')?.discount_amount || 0,
        payment_premium: subContractDiscounts?.find(d => d?.discount_type == 'PAYMENT_PREMIUM')?.discount_amount || 0,
    })
    const pInputRef = useRef();
    const sInputRef = useRef();
    const plantNameRef = useRef();
    const plantIdRef = useRef();
    const totalValueRef = useRef();
    const totalAllottedRef = useRef();
    const isVclEnabledRef = useRef(false);
    const overviewContent = useRef();
    const selectedItemId = useRef();
    const originalBuyerId = useRef();
    const subContractAvailableQuantity = useRef(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        handleFetchAllPlantData()
        handleFetchItemsFromMasterForCategory(props.location?.state.orderDetails?.item_category?.id)
        handleFetchItemCategory(props.location?.state.orderDetails?.item_category?.id)
        handleFetchBuyers(props.location?.state?.orderDetails?.customer?.id);
        handleFetchConsigneeForCustomer(props.location?.state?.orderDetails?.customer?.id);
        getBankMasterList();
        // handleFetchItemsForCategory(props.location?.state.orderDetails?.item_category?.id)
        setSubcontractData({
            ...subcontractData,
            order: props.location?.state?.orderDetails?.id,
            plant: props.location?.state?.orderPlantPlanningData[0]?.plant,
        })
        { console.log(props.location?.state) }
        let imageUrl = props.location?.state?.subContractDetails?.party_reference_doc
        if (imageUrl && !imageUrl.includes("http")) {
            imageUrl = HOST + props.location?.state?.subContractDetails?.party_reference_doc
        }
        // console.log(imageUrl)
        setPartyRefUpload({
            ...partyRefUpload,
            partyRefFileName: imageUrl || "",
            partyRefUploadFileName: imageUrl || ""
        })
        handleFetchCityDiscountForCategory(props.location?.state?.orderDetails?.item_category?.id)
        handleFetchPaymentMethodDiscountForCategory(props.location?.state?.orderDetails?.item_category?.id)
        handleFetchAllPaymentMethods()
        handleFetchFreightBasis()
        fetchTagsMaster()
        setOrderDetails(props.location?.state?.orderDetails)
        if (!plantNameRef.current) {
            plantNameRef.current = name
        }
        if (!plantIdRef.current) {
            plantIdRef.current = plantId
        }
        setTimeout(() => {
            setIsInitialLoading(false);
        }, 2000);

    }, []);

    useEffect(() => {
        if (mode === "addSubContracts") {
            setSubcontractData({
                ...subcontractData,
                item_tag_details: { id: props.location?.state?.orderDetails?.tag || "" }
            })
        }
    }, [tagsMaster])


    useEffect(() => {
        const freightDetails = freightBasis.find(f => f.id == props.location?.state?.orderDetails?.freight_basis)
        setSubcontractData({
            ...subcontractData,
            freight_basis_details: { id: props.location?.state?.orderDetails?.freight_basis, name: freightDetails?.name },
            freight_charge_rate: freightDetails?.name == 'FOR' ? props.location?.state?.orderDetails?.freight_charge_percent : 0
        })

    }, [freightBasis])

    useEffect(() => {
        if (mode == 'editSubContracts' || mode == "overview") {
            if (AllPaymentTerms.length > 0) {
                oldSubcontractQuantity.current = subContractDetails?.allotted_qty
                setSubcontractData({
                    ...subcontractData,
                    subContract_quantity: subContractDetails?.allotted_qty,
                    payment_term_details: AllPaymentTerms.find(a => subContractDetails?.payment_detail?.payment_name == a.payment_method_name),
                    payment_doc_number: subContractDetails?.payment_doc_number,
                    payment_doc_date: subContractDetails?.payment_doc_date,
                    payment_doc_value: subContractDetails?.payment_doc_value,
                    payment_bank_details: bankMasterList.find(b => b.bank_name == subContractDetails?.payment_detail?.payment_bank_name),
                    payment_doc_valid_upto: subContractDetails?.payment_doc_valid_upto,
                    payment_doc_remark: subContractDetails?.payment_doc_remark,
                    freight_basis_details: freightBasis.find(f => f.id == subContractDetails?.freight_basis),
                    cess_rate: subContractDetails?.cess_rate,
                    freight_charge_rate: subContractDetails?.freight_charge_rate,
                    freight_advance: subContractDetails.freightBasis == 1 ? subContractDetails?.freight_advance_percent : subContractDetails?.freight_advance_amount,
                    item_tag_details: { id: subContractDetails?.tag || "" },
                    buyer: subContractDetails?.buyer_detail?.buyer_id,
                })
                originalBuyerId.current = subContractDetails?.buyer_detail?.buyer_id
                setAdditionalData(buyerSelectionList.find(b => b.id == subContractDetails?.buyer_detail?.buyer_id))
                setConsigneeData(allConsigneeList.find(b => b.id == subContractDetails?.consignee_detail?.id))
                setPaymentMethodDiscount(discountInitialData?.payment_premium)
                setPaymentBasisDiscount(discountInitialData?.payment_discount)
                const paymentTerm = AllPaymentTerms.find(a => a.payment_method_name == subContractDetails?.payment_detail?.payment_name)
                isVclEnabledRef.current = AllPaymentTerms.some((item) => item.payment_method_name === paymentTerm?.payment_method_name && item.is_vcl_enabled)
            }

            setIsTotalValueValid(true);
            setIsTotalItemValueValid(true);
            setAllottedQtyValid(true);
        }
    }, [AllPaymentTerms, freightBasis, allConsigneeList])

    useEffect(() => {
        if (mode == 'editSubContracts' || mode == "overview") {
            if (AllPaymentTerms.length > 0) {
                const itemDetails = subContractDetails?.sub_contract_items
                    ?.map(s => ({
                        ...allItemsList?.find(i => i.id == s.item),
                        rate_difference: getRateDiff(allItemsList?.find(i => i.id == s.item)),
                        allotted_qty: s.allotted_qty,
                        remark: s.remark,
                        item: s.item,
                        id: s.id,
                        sub_contract_item_id: s.id,
                        dispached_qty: s.dispached_qty,
                        available_qty: s.available_qty,
                        la_qty: s.la_qty
                    }))
                subContractAvailableQuantity.current =
                    Number(oldSubcontractQuantity.current) - itemDetails.reduce((acc, curr) => Number(acc) + Number(curr.allotted_qty), 0)
                const additionalItemDetails = itemDetails.map((item) => {
                    const totalTaxableAmt = getTotalDiscountedRate(Number(item?.rate_difference)) * (item.allotted_qty - item.dispached_qty)
                    item.totalTaxableAmt = totalTaxableAmt
                    const result = handleCalculateTotalTaxableAmt(totalTaxableAmt)
                    item.sgst = result.sgstAmount
                    item.cgst = result.cgstAmount
                    item.igst = result.igstAmount
                    item.totalAmt = result.totalAmt
                    item.item_value = Number(Number(item.totalTaxableAmt) +
                        Number(item.totalAmt) +
                        calculatePercent(tcsRate?.tcs_rate, (Number(item.totalTaxableAmt) + Number((item.totalAmt || 0)))) +
                        Number(subcontractData.cess_rate || 0.00)).toFixed(2)
                    return item
                })
                const subContractItems = additionalItemDetails.map(a => ({ id: a.id, item: a.item, allotted_qty: a.allotted_qty, item_value: a.item_value, remark: a.remark, sub_contract_item_id: a.sub_contract_item_id, dispached_qty: a.dispached_qty, la_qty: a.la_qty, available_qty: a.available_qty, method: UNCHANGE }))
                const receivedSubContractItems = additionalItemDetails.map(a => ({ id: a.id, item: a.item, allotted_qty: a.allotted_qty, item_value: a.item_value, remark: a.remark, sub_contract_item_id: a.sub_contract_item_id, dispached_qty: a.dispached_qty, available_qty: a.available_qty, la_qty: a.la_qty, method: UNCHANGE }))
                totalAllottedRef.current = Number(subContractItems?.map(item => Number(item.allotted_qty)).reduce((a, b) => a + b, 0)).toFixed(3)
                totalValueRef.current = Number(subContractItems?.map(item => Number(item.item_value)).reduce((a, b) => a + b, 0)).toFixed(2)
                setItemDetailsData(itemDetails)
                if (mode == 'editSubContracts') {
                    const _itemDetails = itemDetails.map(i => ({ ...i }))
                    setItemDetailsDataOriginal(_itemDetails)
                    const itemQty = itemDetails.map(i => Number(i.allotted_qty))?.reduce((a, b) => a + b, 0);
                    console.log(itemQty, subcontractAvailableQty, "subQty", Number(subcontractData.subContract_quantity))
                    setOriginalQuantity(Number(subcontractData.subContract_quantity))
                }
                setSubcontractData({ ...subcontractData, sub_contract_items: subContractItems })
                setReceivedSubContractItems(receivedSubContractItems)
            }
        }
    }, [tcsRate])

    useEffect(() => {
        let cityPincodeData = categoryCityDiscountData?.find((city) =>
            city?.pincodes.length > 0
                ? city?.pincodes.includes(
                    city?.pincodes.find((el) => el.pincode === Number(buyerPinCode))
                )
                : false
        );
        getCityDiscount(cityPincodeData)
    }, [categoryCityDiscountData])

    useEffect(() => {
        getCityDiscount(cityPincodeData)
    }, [cityPincodeData])

    useEffect(() => {
        setDiscountData(orderDetails?.discounts)
        if (mode == 'editSubContracts' || mode == "overview") {
            setBuyerPinCode(subContractDetails?.buyer_detail?.address?.pincode)
        } else {
            setBuyerPinCode(orderDetails?.buyer?.address?.pincode)
        }
    }, [orderDetails])

    useEffect(() => {
        if (pInputRef.current) {
            pInputRef.current.value = "";
        }
        if (sInputRef.current) {
            sInputRef.current.value = "";
        }
    }, [subcontractData["payment_term"]])

    useEffect(() => {
        handleIdentifyGST()
    }, [additionalData, allPlantsData, itemCategoryData])


    useEffect(() => {
        if (itemDetailsData.length > 0) {
            selectedItemId.current = itemDetailsData[0].item;
            handleFetchTcsRateData(additionalData?.buyerid, selectedItemId.current)
        } else if (itemDetailsData.length == 0 && allItemsList[0]?.id && (mode == 'editSubContracts' || mode == "overview")) {
            handleFetchTcsRateData(additionalData?.buyerid, allItemsList[0]?.id)
        }
    }, [additionalData, allItemsList])

    useEffect(() => {
        if (subcontractData?.item_tag_details) {
            handleFetchItemsForCategory(subcontractData?.item_tag_details?.id)
        }
    }, [subcontractData.item_tag_details])

    const handleAllottedQuantityChange = (value, index) => {
        setHasItemError(false);
        setItemErrorMessage(null)
        const items = [...itemDetailsData]
        const selectedItem = items[index]
        selectedItem.allotted_qty = value
        const totalTaxableAmt = getTotalDiscountedRate(Number(selectedItem?.rate_difference)) * selectedItem.allotted_qty
        selectedItem.totalTaxableAmt = totalTaxableAmt
        const result = handleCalculateTotalTaxableAmt(totalTaxableAmt)
        selectedItem.sgst = result.sgstAmount
        selectedItem.cgst = result.cgstAmount
        selectedItem.igst = result.igstAmount
        selectedItem.totalAmt = result.totalAmt
        selectedItem.hasAllottedQtyErr = false
        if (mode == "editSubContracts") {
            selectedItem.available_qty = Number(value) - Number(selectedItem.la_qty)
        }
        // if (mode == "editSubContracts") {
        //     const diff = Number(itemDetailsDataOriginal[index]?.allotted_qty) - Number(value)
        //     selectedItem.available_qty = Number(itemDetailsDataOriginal[index]?.allotted_qty) + diff
        // }
        const subContractItems = subcontractData.sub_contract_items
        const subContractItem = subContractItems[index]
        subContractItem.allotted_qty = value
        subContractItem.item_value =
            Number(Number(itemDetailsData[index]?.totalTaxableAmt) +
                Number(itemDetailsData[index]?.totalAmt) +
                calculatePercent(tcsRate?.tcs_rate, (Number(itemDetailsData[index]?.totalTaxableAmt) + Number((itemDetailsData[index]?.totalAmt || 0)))) + Number(subcontractData.cess_rate || 0.00)).toFixed(2)
        selectedItem.item_value = subContractItem.item_value

        const existingItem = receivedSubContractItems.find(r => r.id == subContractItem.id)
        let noError = true
        if (existingItem && existingItem.allotted_qty == value) {
            subContractItem.method = UNCHANGE
        } else {
            if (existingItem) {
                subContractItem.method = UPDATED
                if (value < subContractItem.dispached_qty) {
                    console.log("a")
                    noError = false
                    selectedItem.hasAllottedQtyErr = true
                    selectedItem.AllottedQtyErr = "Allotted quantity cannot be less than dispatched quantity"
                } else if (value < subContractItem.la_qty) {
                    console.log("b")
                    noError = false
                    selectedItem.hasAllottedQtyErr = true
                    selectedItem.AllottedQtyErr = "Allotted quantity cannot be less than LA quantity"
                } else if (!value.toString().match(/^\d*\.?\d{0,3}$/)) {
                    console.log("c")
                    noError = false
                    selectedItem.hasAllottedQtyErr = true
                    selectedItem.AllottedQtyErr = "Enter upto 3 decimal digit"
                }
                else {
                    noError = true
                    selectedItem.hasAllottedQtyErr = false
                    selectedItem.AllottedQtyErr = ""
                }
            } else {
                subContractItem.method = ADDED
            }
        }
        setItemDetailsData(items)
        setSubcontractData({ ...subcontractData, sub_contract_items: subContractItems })
        subContractAvailableQuantity.current = Number(subcontractData?.subContract_quantity) - items.reduce((acc, curr) => Number(acc) + Number(curr.allotted_qty), 0)
        totalValueRef.current = Number(items?.map(item => Number(item.item_value)).reduce((a, b) => a + b, 0)).toFixed(2)
        totalAllottedRef.current = Number(items?.map(item => Number(item.allotted_qty)).reduce((a, b) => a + b, 0)).toFixed(3)
        const qty = items.map(item => Number(item.allotted_qty)).reduce((a, b) => a + b, 0)
        const avalaibleQnty = Number(originalQuantity) - qty
        setAvaliableQty(avalaibleQnty)

        if (value == '') {
            console.log("d")
            noError = false
            setIsTotalValueValid(false)
            setIsTotalItemValueValid(false)
            setAllottedQtyValid(false)
        } else if (value <= 0) {
            console.log("e")
            noError = false
            selectedItem.hasAllottedQtyErr = true
            selectedItem.AllottedQtyErr = "Allotted quantity must be greater than zero"
        } else if (!value.toString().match(/^\d*\.?\d{0,3}$/)) {
            console.log("f")
            noError = false
            selectedItem.hasAllottedQtyErr = true
            selectedItem.AllottedQtyErr = "Enter upto 3 decimal digit"
        } else {
            if (Number(subcontractData?.payment_doc_value) && isVclEnabledRef.current) {
                if (Number(subcontractData?.payment_doc_value) < totalValueRef.current) {
                    console.log("g")
                    noError = false
                    setIsTotalValueValid(false)
                } else {
                    setIsTotalValueValid(true)
                }
            }

            if (Number(subcontractData?.subContract_quantity) < totalAllottedRef.current) {
                console.log("h")
                noError = false
                setIsTotalItemValueValid(false)
            } else {
                setIsTotalItemValueValid(true)
            }

            if (Number(subcontractData?.subContract_quantity) - qty < 0) {
                console.log("i=", Number(subcontractData?.subContract_quantity), originalQuantity, qty, Number(originalQuantity) - qty)
                noError = false
                setAllottedQtyValid(false)
            } else {
                setAllottedQtyValid(true)
            }

            if (noError) {
                setHasItemError(false)
            } else {
                console.log("1")
                setHasItemError(true)
            }
        }
    }
    const handleOpenSnackbar = (message, type) => {
        setSnackbarData({
            openSnackbar: true,
            snackbarMessage: message,
            snackbarType: type,
        });
    };

    const handleSnackbarMessageBox = (data) => {
        return (
            data.map((item, index) => {
                return (
                    <>
                        <p style={{ margin: 0 }}>
                            {item}
                        </p>
                    </>)
            })
        )
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleItems = () => {
        const itemsData = [...itemDetailsData];
        setHasItemError(false)

        const hasError = itemsData.some((i) => i.item == "" || i.item == null)
        if (hasError) {
            console.log("2")
            setHasItemError(true)
        }

        const totalAllotedQty = itemsData.reduce((acc, curr) => {
            return acc + Number(curr.allotted_qty);
        }, 0);

        //const qtyError = Number(totalAllotedQty) > Number(avaliableQty).toFixed(3);
        const qtyError = Number(avaliableQty).toFixed(3) < 0;
        if (qtyError) {
            console.log("3")
            setHasItemError(true)
            setItemErrorMessage("Please fill total item quantity less than or equal to the available quantity")
            return
        }
        if (itemsData.length == 0) {
            console.log("4")
            setHasItemError(true)
            setItemErrorMessage("Add atleast one item")
            return
        }

        if (Number(subcontractData?.payment_doc_value) && isVclEnabledRef.current) {
            if (Number(subcontractData?.payment_doc_value) < totalValueRef.current) {
                console.log("5")
                setHasItemError(true)
                //setItemErrorMessage("Please fill value less than payment document value")
                setIsTotalValueValid(false)
            } else {
                setHasItemError(false)
                setItemErrorMessage(null)
            }
        }

        if (Number(subcontractData?.subContract_quantity) < totalAllottedRef.current) {
            console.log("6")
            setHasItemError(true)
            //setItemErrorMessage("Total Item Value cannot be more than Subcontract Quantity")
            setIsTotalItemValueValid(false)
        } else {
            setHasItemError(false)
            setItemErrorMessage(null)
        }
        if (mode == 'editSubContracts') {
            const formData = new FormData();

            formData.append('buyer', subcontractData?.buyer);
            formData.append('cess_rate', subcontractData.cess_rate);
            subcontractData?.consignee && formData.append('consignee', subcontractData.consignee);
            formData.append('freight_advance_amount', subcontractData.freight_advance);
            formData.append('freight_basis', subcontractData.freight_basis_details?.id);
            formData.append('freight_charge_rate', subcontractData.freight_charge_rate);
            formData.append('order', props.location?.state?.orderDetails?.id);
            formData.append('plant', plantIdRef.current);
            subcontractData.payment_doc_number && formData.append('payment_doc_number', subcontractData.payment_doc_number || "");
            formData.append('payment_doc_value', subcontractData.payment_doc_value || "");
            formData.append('payment_doc_remark', subcontractData.payment_doc_remark || "");
            formData.append('payment_term', subcontractData.payment_term_details?.id);
            formData.append('tag', subcontractData.item_tag_details?.id);
            formData.append('allotted_qty', subcontractData.subContract_quantity);
            // formData.append('sub_contract_items', JSON.stringify(subcontractData.sub_contract_items)); // Assuming it's an array or object
            formData.append('payment_doc_date', isVclEnabledRef.current ? moment(subcontractData?.payment_doc_date).format("YYYY-MM-DD") : "");
            formData.append('payment_doc_valid_upto', isVclEnabledRef.current ? moment(subcontractData?.payment_doc_valid_upto).format("YYYY-MM-DD") : "");
            subcontractData?.payment_bank_details?.id && formData.append('payment_bank', subcontractData?.payment_bank_details?.id || "");
            formData.append('party_reference_doc', partyRefUpload?.partyRefRawUploadedFile || "");

            subcontractData.sub_contract_items.forEach((element, i) => {
                Object.keys(element).forEach(itemKey => {
                    // console.log(`sub_contract_items_[${i}]_${itemKey}`, element, itemKey)
                    formData.append(`sub_contract_items[${i}]${itemKey}`, element[itemKey] || "");
                })
            });

            handleSaveUpdateSubcontract(formData)
            // handleSaveUpdateSubcontract({
            //     buyer: subcontractData?.buyer,
            //     cess_rate: subcontractData.cess_rate,
            //     consignee: subcontractData.consignee,
            //     freight_advance_amount: subcontractData.freight_advance,
            //     freight_basis: subcontractData.freight_basis_details?.id,
            //     freight_charge_rate: subcontractData.freight_charge_rate,
            //     order: props.location?.state?.orderDetails?.id,
            //     plant: plantIdRef.current,
            //     payment_doc_number: subcontractData.payment_doc_number,
            //     payment_doc_value: subcontractData.payment_doc_value,
            //     payment_doc_remark: subcontractData.payment_doc_remark,
            //     payment_term: subcontractData.payment_term_details?.id,
            //     tag: subcontractData.item_tag_details?.id,
            //     allotted_qty: subcontractData.subContract_quantity,
            //     sub_contract_items: subcontractData.sub_contract_items,
            //     payment_doc_date: isVclEnabledRef.current ? moment(subcontractData?.payment_doc_date).format("YYYY-MM-DD") : null,
            //     payment_doc_valid_upto: isVclEnabledRef.current ? moment(subcontractData?.payment_doc_valid_upto).format("YYYY-MM-DD") : null,
            //     payment_bank: subcontractData?.payment_bank_details?.id,
            //     party_ref_doc: partyRefUpload?.partyRefRawUploadedFile || ""

            // })

        } else {
            const formData = new FormData();

            formData.append('buyer', subcontractData?.buyer);
            formData.append('cess_rate', subcontractData.cess_rate);
            formData.append('consignee', subcontractData.consignee);
            formData.append('freight_advance_amount', subcontractData.freight_advance);
            formData.append('freight_basis', subcontractData.freight_basis_details?.id);
            formData.append('freight_charge_rate', subcontractData.freight_charge_rate);
            formData.append('order', props.location?.state?.orderDetails?.id);
            formData.append('plant', plantIdRef.current);
            formData.append('payment_doc_number', subcontractData.payment_doc_number || "");
            formData.append('payment_doc_value', subcontractData.payment_doc_value || "");
            formData.append('payment_doc_remark', subcontractData.payment_doc_remark || "");
            formData.append('payment_term', subcontractData.payment_term_details?.id);
            formData.append('tag', subcontractData.item_tag_details?.id);
            formData.append('allotted_qty', subcontractData.subContract_quantity);
            // formData.append('sub_contract_items', JSON.stringify(subcontractData.sub_contract_items)); // Assuming it's an array or object
            formData.append('payment_doc_date', isVclEnabledRef.current ? moment(subcontractData?.payment_doc_date).format("YYYY-MM-DD") : "");
            formData.append('payment_doc_valid_upto', isVclEnabledRef.current ? moment(subcontractData?.payment_doc_valid_upto).format("YYYY-MM-DD") : "");
            formData.append('payment_bank', subcontractData?.payment_bank_details?.id || "");
            formData.append('party_reference_doc', partyRefUpload?.partyRefRawUploadedFile || "");

            subcontractData.sub_contract_items.forEach((element, i) => {
                Object.keys(element).forEach(itemKey => {
                    // console.log(`sub_contract_items_[${i}]_${itemKey}`, element, itemKey)
                    formData.append(`sub_contract_items[${i}]${itemKey}`, element[itemKey] || "");
                })
            });
            console.log(formData, subcontractData.sub_contract_items)
            handleSaveAddSubcontract(formData)

            // handleSaveAddSubcontract({
            //     buyer: subcontractData?.buyer,
            //     cess_rate: subcontractData.cess_rate,
            //     consignee: subcontractData.consignee,
            //     freight_advance_amount: subcontractData.freight_advance,
            //     freight_basis: subcontractData.freight_basis_details?.id,
            //     freight_charge_rate: subcontractData.freight_charge_rate,
            //     order: props.location?.state?.orderDetails?.id,
            //     plant: plantIdRef.current,
            //     payment_doc_number: subcontractData.payment_doc_number,
            //     payment_doc_value: subcontractData.payment_doc_value,
            //     payment_doc_remark: subcontractData.payment_doc_remark,
            //     payment_term: subcontractData.payment_term_details?.id,
            //     tag: subcontractData.item_tag_details?.id,
            //     allotted_qty: subcontractData.subContract_quantity,
            //     sub_contract_items: subcontractData.sub_contract_items,
            //     payment_doc_date: isVclEnabledRef.current ? moment(subcontractData?.payment_doc_date).format("YYYY-MM-DD") : null,
            //     payment_doc_valid_upto: isVclEnabledRef.current ? moment(subcontractData?.payment_doc_valid_upto).format("YYYY-MM-DD") : null,
            //     payment_bank: subcontractData?.payment_bank_details?.id,
            //     party_ref_doc: partyRefUpload?.partyRefRawUploadedFile || ""
            // })
        }


    };

    const handleItemChange = (index, value) => {
        const newItems = [...items];
        newItems[index].item = value;
        setItems(newItems);
    };

    const handleQuantityChange = (index, value) => {
        const newItems = [...items];
        newItems[index].quantity = value;
        setItems(newItems);
    };

    const handleAddItem = () => {
        setItems([...items, { item: '', quantity: '' }]);
    };

    const handleDeleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    function handleConfirmationDialogOpen() {
        setConfirmationDialogOpen(!confirmationDialogOpen);
    }

    function handleConfirmationDialogClose() {
        setConfirmationDialogOpen(false);
    }

    function handleMarkClientApproved() {
        props.handleCustomSetState("isClientApproved", true);
        setConfirmationDialogOpen(false);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarData({
            ...snackbarData,
            openSnackbar: false,
            snackbarMessage: "",
            snackbarType: "",
        });
    };

    const handleFetchBuyers = (customerId) => {
        props
            .fetchBuyers(customerId)
            .then((response) => {
                if (response.status === 200) {
                    setBuyerSelectionList(response.data.results);
                    const buyerId = orderDetails?.buyer?.buyer_id
                    if (buyerId) {
                        const buyer = response.data.results.find((buyer) => buyer.id === buyerId)
                        if (buyer) {
                            setAdditionalData(buyer)
                            setSubcontractData(p => ({ ...p, buyer: buyer?.id }))
                        }
                    }
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching buyers list",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                setSnackbarData({
                    ...snackbarData,
                    openSnackbar: true,
                    snackbarMessage: error?.data?.details
                        ? error?.data?.details
                        : error?.data?.message
                            ? error?.data?.message
                            : "Error occured while fetching buyers list",
                    snackbarType: "error",
                });
            });
    };

    const handleFetchConsigneeForCustomer = (customerId) => {
        axios.get(
            `${APIROUTES.CONTRACTS_CONSIGNEE}${customerId}/`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setAllConsigneeList(response.data.results);
                    const consigneeId = props.location?.state?.subContractDetails?.consignee_detail?.id !== undefined
                        ? props.location?.state?.subContractDetails?.consignee_detail?.id
                        : orderDetails?.consignee?.id
                    if (consigneeId) {
                        const consignee = response.data.results.find((consignee) => consignee.id === consigneeId)
                        if (consignee) {
                            setConsigneeData(consignee)
                            setSubcontractData(p => ({ ...p, consignee: consignee?.id }))
                        }
                    }
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching consignee list",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                setSnackbarData({
                    ...snackbarData,
                    openSnackbar: true,
                    snackbarMessage: error?.data?.details
                        ? error?.data?.details
                        : error?.data?.message
                            ? error?.data?.message
                            : "Error occured while fetching consignee list",
                    snackbarType: "error",
                });
            });
    };

    //Temp
    const requestInfo = {
        total_subcontract_value: 10
    }
    const paymentTermsValidate = () => {
        const errors = {}
        const requiredErrorMsg = "This field is required";

        if (!subcontractData?.payment_term_details) {
            errors.payment_term = requiredErrorMsg;
            setErrors({ ...errors, payment_term: requiredErrorMsg });
        }
        if (!subcontractData?.item_tag_details?.id) {
            errors.item_tag = requiredErrorMsg;
            setErrors({ ...errors, item_tag: requiredErrorMsg });
        }


        if (AllPaymentTerms.some((item) => item.payment_method_name === subcontractData?.payment_term_details?.payment_method_name && item.is_vcl_enabled)) {
            if (!subcontractData?.payment_doc_value) {
                errors.payment_doc_value = requiredErrorMsg;
                setErrors({ ...errors, payment_doc_value: requiredErrorMsg });
            }
            if (!subcontractData?.payment_doc_number) {
                errors.payment_doc_number = requiredErrorMsg;
                setErrors({ ...errors, payment_doc_number: requiredErrorMsg });
            }
            if (!subcontractData?.payment_bank_details) {
                errors.payment_bank = requiredErrorMsg;
                setErrors({ ...errors, payment_bank: requiredErrorMsg });
            }
            if (!subcontractData?.payment_doc_date) {
                errors.payment_doc_date = requiredErrorMsg;
                setErrors({ ...errors, payment_doc_date: requiredErrorMsg });
            }
            if (!subcontractData?.payment_doc_valid_upto) {
                errors.payment_doc_valid_upto = requiredErrorMsg;
                setErrors({ ...errors, payment_doc_valid_upto: requiredErrorMsg });
            } else if (subcontractData?.payment_doc_valid_upto) {
                if (Moment(subcontractData.payment_doc_date).format('MM/DD/YYYY') >= (Moment(subcontractData.payment_doc_valid_upto).format('MM/DD/YYYY'))) {
                    errors.payment_doc_valid_upto = "Payment document valid upto must be greater than payment document date";
                    setErrors({ ...errors, payment_doc_valid_upto: errors.payment_doc_valid_upto });
                }
            }
            if (!subcontractData?.subContract_quantity) {
                errors.subContract_quantity = requiredErrorMsg;
                setErrors({ ...errors, subContract_quantity: requiredErrorMsg });
            } else if (subcontractData?.subContract_quantity) {
                if (mode == 'editSubContracts') {
                    if (Number(newAvailableQuantity) < 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be less than available quantity";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    } else if (Number(subcontractData.subContract_quantity) <= 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be greater than zero.";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    }
                } else {
                    if (Number(subcontractData.subContract_quantity) > Number(Number(newAvailableQuantity).toFixed(3))) {
                        errors.subContract_quantity = "Total subcontract quantity must be less than available quantity";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    } else if (Number(subcontractData.subContract_quantity) <= 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be greater than zero.";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    }
                }
            }
        }
        else {
            if (!subcontractData?.subContract_quantity) {
                errors.subContract_quantity = requiredErrorMsg;
                setErrors({ ...errors, subContract_quantity: requiredErrorMsg });
            } else if (subcontractData?.subContract_quantity) {
                if (mode == 'editSubContracts') {
                    if (Number(newAvailableQuantity) < 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be less than available quantity";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    } else if (Number(subcontractData.subContract_quantity) <= 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be greater than zero.";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    }
                } else {
                    if (Number(subcontractData.subContract_quantity) > Number(Number(newAvailableQuantity).toFixed(3))) {
                        errors.subContract_quantity = "Total subcontract quantity must be less than available quantity";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    } else if (Number(subcontractData.subContract_quantity) <= 0) {
                        errors.subContract_quantity = "Total subcontract quantity must be greater than zero.";
                        setErrors({ ...errors, subContract_quantity: errors.subContract_quantity });
                        setIsSubcontractQtyValid(false)
                    }
                }
            }
        }
        return errors;
    }

    const getOptions = (user) => {
        return {
            headers: {
                Authorization: `JWT ${user.token}`,
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    };



    const handleFetchItemsForCategory = (tagId) => {
        axios.get(
            `${APIROUTES.GET_ITEMS_PER_TAGS}?tag=${tagId}&category_id=${orderDetails?.item_category?.id}`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setAllItemsList(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching items list",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleFetchItemsFromMasterForCategory = (itemCategoryId) => {
        axios.get(
            `${APIROUTES.ITEM_CATEGORY_MASTER_ITEMS}?id=${itemCategoryId}&active=${true}`,
            getOptions(props.user)
        )
            ?.then((response) => {
                if (response.status === 200) {
                    setAllItemsListMaster(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching items list",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };


    const handleFetchAllPaymentMethods = () => {
        axios.get(
            `${APIROUTES.GET_ALL_PAYMENT_METHODS}?active=${true}`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setAllPaymentTerms(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching all payment methods list",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleFetchFreightBasis = () => {
        axios.get(
            `${APIROUTES.FREIGHT_BASIS}`,
            getOptions(props.user)
        )
            .then((response) => {
                if (response.status === 200) {
                    setFreightBasis(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching freight basis",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const getBankMasterList = () => {
        axios.get(
            `${APIROUTES.BANK_MASTER}?active=${true}`, getOptions(props.user)).then(
                (response) => {
                    let responseData = arraySortBykeyAllBrowser(response.data, 'bank_name');
                    setBankMasterList(responseData);
                },
                (error) => {
                }
            );
    };


    const getTotalDiscount = () => {
        let total =
            Number(cityDiscount || 0) +
            Number(discountData?.customer_discount || 0) +
            Number(paymentBasisDiscount || 0) +
            Number(discountData?.special_discount || 0);
        return total;
    };

    const getTotalDiscountedRate = (rateDifference) => {
        let contractRate = orderDetails?.rate;
        let paymentPremium = paymentMethodDiscount;
        let loadingPremium = orderDetails?.discounts?.loading_premium;
        let insurancePremium = orderDetails?.discounts?.insurance_premium;
        let freightRate = subcontractData.freight_charge_rate;
        let allDiscounts = getTotalDiscount()
        let Total =
            Number(contractRate || 0.00) +
            Number(rateDifference || 0.00) +
            Number(paymentPremium || 0.00) +
            Number(loadingPremium || 0.00) +
            Number(insurancePremium || 0.00) +
            Number(freightRate || 0.00) -
            Number(allDiscounts || 0.00);
        return Total;
    }

    const handleSaveAddSubcontract = (subcontractData) => {
        axios.post(`${APIROUTES.SUB_CONTRACT_ADD}`, subcontractData,
            getOptions(props?.user)
        )
            .then(res => {
                if (res.status === 201) {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: "subcontract added successfully",
                        snackbarType: "success",
                    });
                    setTimeout(() => {
                        props.history.push(
                            `/internalContracts/singleContracts/${orderDetails.id}`
                        );
                    }, 4000)
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    }


    const handleSaveUpdateSubcontract = (subcontractData) => {
        axios.patch(`${APIROUTES.UPDATE_SUB_CONTRACT}${id}/`, subcontractData,
            getOptions(props?.user)
        )
            .then(res => {
                setSnackbarData({
                    ...snackbarData,
                    openSnackbar: true,
                    snackbarMessage: "Subcontract updated successfully",
                    snackbarType: "success",
                });
                setTimeout(() => {
                    props.history.push(
                        `/internalContracts/singleContracts/${orderDetails.id}`
                    );
                }, 4000)

            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    }

    const handleFetchCityDiscountForCategory = (itemCategoryId) => {
        axios
            .get(
                `${APIROUTES.ITEM_CATEGORY_CITY_DISCOUNT}?id=${itemCategoryId}`,
                getOptions(props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    setCategoryCityDiscountData(response.data);
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const getCityDiscount = (data) => {
        if ((originalBuyerId.current == additionalData?.id && mode == 'editSubContracts') || mode == 'overview') {
            setCityDiscount(discountInitialData?.city_discount)
            return
        }
        const createdAt = new Date(orderDetails?.created_at);
        const cityDiscount = data?.discount_amount;
        const cityDiscountLogs = data?.city_discount_logs;

        const qs1 = cityDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        const qs2 = cityDiscountLogs?.find((r) =>
            r.valid_upto != null
            && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
            && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        if (qs1) {
            setCityDiscount(qs1)
        } else if (qs2) {
            setCityDiscount(qs2)
        } else {
            setCityDiscount(cityDiscount);
        }

    }

    const getRateDiff = (data) => {
        const createdAt = new Date(props?.location?.orderDetails?.created_at);
        const currentItemId = data?.id
        const rateDiff = allItemsList.find(f => f.id === currentItemId)?.rate_difference;
        const rateDiffs = allItemsList.find(f => f.id === currentItemId)?.rate_difference_logs;

        const qs1 = rateDiffs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        const qs2 = rateDiffs?.find((r) =>
            r.valid_upto != null
            && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
            && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        if (mode == "overview") {
            return rateDiff;
        } else {
            if (qs1) {
                return qs1
            } else if (qs2) {
                return qs2
            } else {
                return rateDiff;
            }
        }
    }

    const getPaymentBasisPremiumDiscount = (data) => {
        const createdAt = new Date(orderDetails?.created_at);
        const paymentBasisPremiumDiscount = checkInvalidValue(data?.premium_amount) ? 0 : data?.premium_amount;
        const paymentBasisPremiumDiscountLogs = data?.payment_method_wise_premium_amount_logs;

        const qs1 = paymentBasisPremiumDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        const qs2 = paymentBasisPremiumDiscountLogs?.find((r) =>
            r.valid_upto != null
            && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
            && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        if (qs1) {
            setPaymentMethodDiscount(qs1)
        } else if (qs2) {
            setPaymentMethodDiscount(qs2)
        } else {
            setPaymentMethodDiscount(paymentBasisPremiumDiscount)
        }

    }

    const getPaymentBasisDiscount = (data) => {
        const createdAt = new Date(orderDetails?.created_at);
        const paymentBasisDiscount = checkInvalidValue(data?.discount_amount) ? 0 : data?.discount_amount;
        const paymentBasisDiscountLogs = data?.payment_method_wise_discount_amount_logs;
        const qs1 = paymentBasisDiscountLogs?.find((r) => r.valid_upto == null && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;
        const qs2 = paymentBasisDiscountLogs?.find((r) =>
            r.valid_upto != null
            && moment(new Date(r.valid_upto)).isSameOrAfter(createdAt, 'day')
            && moment(new Date(r.valid_from)).isSameOrBefore(createdAt, 'day'))?.value;

        if (qs1) {
            setPaymentBasisDiscount(qs1)
        } else if (qs2) {
            setPaymentBasisDiscount(qs2)
        } else {
            setPaymentBasisDiscount(paymentBasisDiscount);
        }
    }

    const handleFetchPaymentMethodDiscountForCategory = (itemCategoryId) => {
        axios
            .get(
                `${APIROUTES.ITEM_CATEGORY_PAYMENT_TERM_DISCOUNT}?id=${itemCategoryId}`,
                getOptions(props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    setCategoryPaymentMethodDiscountData(response.data);
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const fetchTagsMaster = () => {
        axios
            .get(`${APIROUTES.GET_ALL_TAGS_MASTERS_LIST}?active=${true}`, getOptions(props.user))
            .then((response) => {
                setTagsMaster(response.data);
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleFetchAllPlantData = () => {
        axios
            .get(
                APIROUTES.GET_ALL_PLANT_ITEM_MASTER_LIST,
                getOptions(props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    setAllPlantsData(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching all plants data",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleFetchItemCategory = (itemCategoryId) => {
        axios
            .get(
                `${APIROUTES.MM_CREATE_NEW_CATEGORY}${itemCategoryId}/`,
                getOptions(props.user)
            )
            .then((response) => {
                if (response.status === 200) {
                    setItemCategoryData(response.data)
                }
            })
            .catch((error) => {
                handleOpenSnackbar(
                    checkApiError(error?.response?.data),
                    "error"
                );
                setTimeout(() => {
                    handleCloseSnackbar();
                }, 4000);
            });
    };

    const handleIdentifyGST = () => {
        let buyerGstin = !additionalData?.gstin_number
            ? null
            : additionalData?.gstin_number;

        let selectedplantData =
            allPlantsData.length > 0
                ? allPlantsData.find(
                    (data) => data.id === plantId
                )
                : {};
        let supplierGstin = selectedplantData?.plant_gst_number || null;
        let buyerState = !buyerGstin ? additionalData?.address?.state_code : buyerGstin.slice(0, 2);
        let supplierState = !supplierGstin ? null : supplierGstin.slice(0, 2);
        let allowGstOption = !buyerState || !supplierState ? true : false;
        setAllowGstOption(allowGstOption)

        let _gstOption = buyerState === supplierState ? "SGST" : "IGST";
        setGstOption(_gstOption);
        let igstRate =
            _gstOption === "IGST"
                ? !itemCategoryData
                    ? 0
                    : Number(itemCategoryData?.igst)
                : 0;
        setIgstRate(igstRate)
        let cgstRate =
            _gstOption !== "IGST"
                ? !itemCategoryData
                    ? 0
                    : Number(itemCategoryData?.cgst)
                : 0;
        setCgstRate(cgstRate)
        let sgstRate =
            _gstOption !== "IGST"
                ? !itemCategoryData
                    ? 0
                    : Number(itemCategoryData?.sgst)
                : 0;
        setSgstRate(sgstRate)
    };

    const handleCalculateTotalTaxableAmt = (taxAmt) => {
        let igstAmount = calculatePercent(igstRate, parseFloat(
            taxAmt || 0
        ));
        let sgstAmount = calculatePercent(sgstRate, parseFloat(
            taxAmt || 0
        ));
        let cgstAmount = calculatePercent(cgstRate, parseFloat(
            taxAmt || 0
        ));
        let totalAmt =
            gstOption === "IGST"
                ? Number(igstAmount)
                : Number(sgstAmount) + Number(cgstAmount);

        const result = { sgstAmount, cgstAmount, igstAmount, totalAmt }
        return result
    }

    const fetchTcsRate = (customerId, itemId) => {
        return axios
            .get(
                `${APIROUTES.GET_TCS_RATE}?customer_id=${customerId}&item_id=${itemId}`,
                getOptions(props.user)
            );
    };

    const handleFetchTcsRateData = (customerId, itemId) => {
        fetchTcsRate(customerId, itemId)
            .then((response) => {
                if (response.status === 200) {
                    setTcsRate(response.data);
                } else {
                    setSnackbarData({
                        ...snackbarData,
                        openSnackbar: true,
                        snackbarMessage: response?.data?.details
                            ? response?.data?.details
                            : response?.data?.message
                                ? response?.data?.message
                                : "Error occured while fetching tcs rate",
                        snackbarType: "error",
                    });
                }
            })
            .catch((error) => {
                setSnackbarData({
                    ...snackbarData,
                    openSnackbar: true,
                    snackbarMessage: error?.data?.details
                        ? error?.data?.details
                        : error?.data?.message
                            ? error?.data?.message
                            : "Error occured while fetching tcs rate",
                    snackbarType: "error",
                });
            });
    };

    const handleFileTypeError = (type, size) => {
        console.log(type, size, 1 * 1024 * 1024)
        let maxSize = 1 * 1024 * 1024
        if (!["application/pdf", "image/png", "image/jpeg"].includes(type)) {
            return 'Invalid File type'
        } else if (size > maxSize) {
            return 'File size exceeds 1 MB'
        }
        else return false
    }

    const handlePartyRefImageChange = (e) => {
        // console.log(e.target.files)
        if (e.target.files.length) {
            setPartyRefUpload({
                partyRefImagePreview: URL.createObjectURL(e.target.files[0]),
                partyRefRawUploadedFile: e.target.files[0],
                partyRefFileName: null,
                partyRefUploadFileName: e.target.files[0].name,
                partyRefFileTypeError: handleFileTypeError(e.target.files[0].type, e.target.files[0].size)
            });
        }
    };

    const handleRemovePartyRefImage = () => {
        setPartyRefUpload({
            partyRefImagePreview: "",
            partyRefRawUploadedFile: "",
            partyRefFileName: "",
            partyRefUploadFileName: null,
            partyRefFileTypeError: ''
        })
    }

    return (
        <Layout {...props}>
            <div className={classes.mainDiv}>
                <div className={classes.rightPadding}>
                    <div className={classes.navigationBar} style={{ flexWrap: 'wrap' }}>
                        <Bread
                            data={[
                                {
                                    title: "Contracts",
                                    onClick: () =>
                                        props.history.push(INTERNALROUTES.ORDERS),
                                },
                                {
                                    title: "Subcontracts",
                                    onClick: () =>
                                        props.history.goBack(),
                                },
                                {
                                    title: mode == 'editSubContracts' ? 'Edit' : mode == "overview" ? "Overview" : 'Add',
                                    //itemOperation === "add" ? "Create New" : `Edit `,
                                    onClick: "",
                                },
                            ]}
                        />
                        {mode == "editSubContracts" ?
                            <>
                                <Divider light={true} orientation="vertical" flexItem style={{ width: 0.11 + "em", marginLeft: "10px" }} />
                                <div className={classes.headerData} style={{ fontSize: isMobile && pxToEm(15) }}>
                                    <span>{subContractDetails?.sub_contract_number} ( {subContractDetails?.status} )</span>
                                </div>
                            </>
                            : ""}
                    </div>
                    {isInitialLoading &&
                        <div className={classes.noDataDiv}>
                            <SpinLoader />
                        </div>
                    }
                    {!isInitialLoading && (
                        <div className={classes.paperDiv}>
                            <Paper elevation={5} className={classes.paperStyle} style={{ padding: isMobile && '20px 0' }}>
                                <div className={classes.createLoadingAdviceMainDiv}
                                    ref={overviewContent}
                                >
                                    <Stepper
                                        classes={{
                                            root: classes.root,
                                        }}
                                        style={{ width: '100%' }}
                                        activeStep={mode == "overview" ? -1 : activeStep}
                                        orientation="vertical"
                                        className={mode == "overview" ? classes.stepperVertical : ""}
                                    >
                                        <Step key="PaymentDetails"
                                            expanded={mode == "overview"}>
                                            <StyledStepLabel
                                                StepIconProps={{
                                                    classes: {
                                                        root: classes.stepIconRoot,
                                                        labelContainer: classes.labelContainer,
                                                    },
                                                }}
                                            >
                                                CONTRACT DETAILS
                                            </StyledStepLabel>
                                            <StepContent>
                                                <div className={classes.generalDetailsDisplaySection} style={{ alignItems: 'center', flexDirection: isMobile && 'column', marginBottom: "30px" }}>
                                                    <div className={classes.dataDisplay} style={{ width: isMobile && '100%', marginBottom: isMobile && 15 }}>
                                                        <div className={'Mui-disabled'}>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                                style={{ margin: isMobile && 0 }}
                                                            >
                                                                <div className={classes.subContractContainer}>
                                                                    <div style={{ width: "100%", height: "75px" }}>
                                                                        <div
                                                                            className={classes.subContractLeftContainer}
                                                                        >
                                                                            <div style={{ width: "65%" }}>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        paddingLeft: "40px",
                                                                                        fontSize: "12.8px"
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                    >
                                                                                        <span>Contract No. : {orderDetails?.order_number}</span>
                                                                                        <span
                                                                                            className={
                                                                                                classes.verticalLineStyle
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <span>
                                                                                        Contract Date : {moment(orderDetails?.created_at).format(
                                                                                            "DD-MM-YYYY"
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: "38%" }}>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        fontSize: "12.8px"
                                                                                    }}

                                                                                >
                                                                                    <span>Contract Rate : {Number(orderDetails?.rate).toFixed(2)}</span>
                                                                                    <span>Contract Quantity : {orderDetails?.quantity} MT</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: isMobile ? '100%' : "48%", marginBottom: isMobile && 15 }}>
                                                        <div className={classes.contractDetailsContainer}>
                                                            <div className={classes.contractDetails}>
                                                                <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <div style={{ flexShrink: '0', width: "35%" }}>Available Quantity: </div>
                                                                    <div style={{ wordWrap: 'break-word', width: '75%' }}>
                                                                        {Number(oldAvailableQuantity.current).toFixed(3)} MT
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <div style={{ flexShrink: '0', width: "35%" }}>Party Reference: </div>
                                                                    <div style={{ wordWrap: 'break-word', width: '65%' }}>
                                                                        {orderDetails?.party_ref_number}
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                                    <div style={{ flexShrink: '0', width: "35%" }}>Party Reference Date: </div>
                                                                    <div style={{ wordWrap: 'break-word', width: '75%' }}>
                                                                        {moment(orderDetails?.party_ref_date).format("DD-MM-YYYY")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.generalDetailsDisplaySection}>
                                                    <div className={classes.dataDisplay}>
                                                        <div style={{ marginBottom: isMobile && 15 }}>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                                style={{ margin: isMobile && 0 }}
                                                            >

                                                                <InputLabel id="plantSelectLable">
                                                                    SELECT PLANT *
                                                                </InputLabel>
                                                                <Select
                                                                    name="plant"
                                                                    id="plantSelectLable"
                                                                    //value={laDetails.plant || ""}
                                                                    value={`${name} - ${Number(subcontractAvailableQty).toFixed(3)} MT` || `${plantNameRef.current} - ${Number(subcontractAvailableQty).toFixed(3)} MT`}
                                                                    disabled={true}
                                                                    MenuProps={MenuProps}
                                                                    style={{ pointerEvents: mode == "overview" && "none" }}
                                                                    label="SELECT PLANT *"
                                                                >
                                                                    <MenuItem value={`${name} - ${Number(subcontractAvailableQty).toFixed(3)} MT` || `${plantNameRef.current} - ${Number(subcontractAvailableQty).toFixed(3)} MT`}>
                                                                        {`${name} - ${Number(subcontractAvailableQty).toFixed(3)} MT` || `${plantNameRef.current} - ${Number(subcontractAvailableQty).toFixed(3)} MT`}
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className={classes.dataDisplay}>
                                                        <div className={classes.dataFields} style={{ padding: 0 }}>
                                                            <Typography className={classes.titleLabel}>
                                                                PARTY REFERENCE DOC
                                                            </Typography>
                                                            <div className={classes.formControlPanImage}>
                                                                {partyRefUpload?.partyRefUploadFileName
                                                                    ? (<div style={{ display: "flex", justifyContent: "space-between", width: "95%", wordBreak: "break-all" }}>
                                                                        <span
                                                                            onClick={() =>
                                                                                window.open(partyRefUpload?.partyRefFileName || partyRefUpload?.partyRefImagePreview, "_blank")
                                                                            }
                                                                            className={classes.titleLabelStylePanImage}
                                                                            style={{ color: secondaryBlueText, marginRight: 10, cursor: 'pointer' }}
                                                                        >
                                                                            {partyRefUpload?.partyRefFileName?.includes("http")
                                                                                ? partyRefUpload?.partyRefFileName?.slice(partyRefUpload?.partyRefFileName.lastIndexOf("/") + 1,)
                                                                                : partyRefUpload?.partyRefFileName || partyRefUpload?.partyRefUploadFileName || ""}
                                                                        </span>
                                                                        {mode == "editSubContracts" || mode == "addSubContracts" ?
                                                                            <DeleteOutlineOutlinedIcon
                                                                                width="20"
                                                                                height="20"
                                                                                onClick={() => handleRemovePartyRefImage()}
                                                                            /> : null
                                                                        }
                                                                    </div>)
                                                                    : (
                                                                        <>
                                                                            <label
                                                                                htmlFor="upload-button"
                                                                                className={classes.fileUploadName}
                                                                            >
                                                                                {partyRefUpload?.partyRefUploadFileName ? (
                                                                                    <div className={classes.generalAlign}>
                                                                                        <Typography className={classes.generalText}>
                                                                                            {partyRefUpload?.partyRefUploadFileName}
                                                                                        </Typography>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className={classes.panImageUpload}>
                                                                                        <PresentToAllOutlinedIcon
                                                                                            className={classes.partyRefImagePreview}
                                                                                        />
                                                                                        <Typography className={classes.generalText}>
                                                                                            Upload
                                                                                        </Typography>
                                                                                    </div>
                                                                                )}
                                                                            </label>
                                                                            <input
                                                                                disabled={
                                                                                    mode == "overview"
                                                                                }
                                                                                type="file"
                                                                                accept="application/pdf, image/*"
                                                                                id="upload-button"
                                                                                style={{ display: "none" }}
                                                                                onChange={(e) => {
                                                                                    e.preventDefault();
                                                                                    handlePartyRefImageChange(e);
                                                                                }}
                                                                                required={true}
                                                                            />
                                                                        </>
                                                                    )}
                                                                {/* {mode == "addSubContracts" ? (
                                                                    <DeleteOutlineOutlinedIcon
                                                                        width="20"
                                                                        height="20"
                                                                        onClick={() => handleRemovePartyRefImage()}
                                                                    />)
                                                                    : null
                                                                } */}
                                                            </div>

                                                        </div>
                                                        {Boolean(partyRefUpload?.partyRefFileTypeError) && <span
                                                            style={{ color: "red", fontSize: "12px" }}>
                                                            {Boolean(partyRefUpload?.partyRefFileTypeError) && partyRefUpload?.partyRefFileTypeError}
                                                        </span>}
                                                        <span
                                                            style={{ fontSize: "10px" }}>
                                                            {"Accepted File Types : pdf/jpg/jpeg/png"}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={classes.generalDetailsDisplaySection}>
                                                    <div className={classes.dataDisplay}>

                                                        <div style={{ marginBottom: isMobile && 15 }}>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                                style={{ margin: isMobile && 0 }}
                                                            >
                                                                <InputLabel id="itemSelectLable">
                                                                    SELECT ITEM CATEGORY *
                                                                </InputLabel>
                                                                <Select
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    style={{ pointerEvents: mode == "overview" && "none" }}
                                                                    name="item_category"
                                                                    id="itemSelectLable"
                                                                    value={orderDetails?.item_category.category_name || ""}
                                                                    MenuProps={MenuProps}
                                                                    label="SELECT ITEM CATEGORY *"
                                                                >
                                                                    <MenuItem value={orderDetails?.item_category.category_name || ""}>
                                                                        {orderDetails?.item_category.category_name || ""}
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>


                                                    </div>
                                                    <div className={classes.dataDisplay}>
                                                        <div style={{ marginBottom: isMobile && 15 }}>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                                style={{ margin: isMobile && 0 }}
                                                            >
                                                                <InputLabel id="itemSelectLable">
                                                                    SELECT ITEM TAG *
                                                                </InputLabel>
                                                                <Select
                                                                    name="item_tag"
                                                                    id="itemSelectLable"
                                                                    MenuProps={MenuProps}
                                                                    label="SELECT ITEM TAG *"
                                                                    value={subcontractData?.item_tag_details?.id || ""}
                                                                    disabled={mode == "editSubContracts" || props.location?.state?.orderDetails?.tag}
                                                                    style={{ pointerEvents: mode == "overview" && "none" }}
                                                                    onChange={(e) => {
                                                                        const itemTag = tagsMaster.find(t => t.id == e.target.value)
                                                                        setSubcontractData(p => ({ ...p, item_tag_details: itemTag }))
                                                                        setErrors({ ...errors, item_tag: "" })
                                                                    }}
                                                                    error={errors?.item_tag}
                                                                >
                                                                    {tagsMaster?.map((tag) => (
                                                                        <MenuItem
                                                                            key={tag.id}
                                                                            value={tag.id}
                                                                        >
                                                                            {tag.tag_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {
                                                                    errors?.item_tag &&
                                                                    <FormHelperText error>
                                                                        {errors.item_tag}
                                                                    </FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className={classes.generalDetailsDisplaySection}>
                                                    <div className={classes.dataDisplay}>
                                                        <div>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                            >
                                                                <InputLabel id="paymentTermSelectLable">
                                                                    SELECT PAYMENT TERMS*
                                                                </InputLabel>
                                                                <Select
                                                                    id="payment_term"
                                                                    name="payment_term"
                                                                    value={subcontractData?.payment_term_details?.payment_method_name || ""}
                                                                    defaultValue={subcontractData?.payment_term_details?.payment_method_name}
                                                                    disabled={mode == 'editSubContracts'}
                                                                    onChange={(e) => {
                                                                        const paymentTerm = AllPaymentTerms.find(a => a.payment_method_name == e.target.value)
                                                                        const data = categoryPaymentMethodDiscountData.find(item => item.payment_method === paymentTerm?.id)
                                                                        isVclEnabledRef.current = AllPaymentTerms.some((item) => item.payment_method_name === paymentTerm?.payment_method_name && item.is_vcl_enabled)
                                                                        setHasItemError(false)
                                                                        if (Number(subcontractData?.payment_doc_value) && isVclEnabledRef.current) {
                                                                            if (Number(subcontractData?.payment_doc_value) < totalValueRef.current) {
                                                                                console.log("7")
                                                                                setHasItemError(true)
                                                                                //setItemErrorMessage("Please fill value less than payment document value")
                                                                                setIsTotalValueValid(false)
                                                                            }
                                                                        } else if (!isVclEnabledRef.current) {
                                                                            setHasItemError(false)
                                                                            setItemErrorMessage(null)
                                                                        }
                                                                        getPaymentBasisPremiumDiscount(data)
                                                                        getPaymentBasisDiscount(data)
                                                                        setSubcontractData(p => ({ ...p, payment_term_details: paymentTerm }))
                                                                        setErrors({ ...errors, payment_term: "" })
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                    label="SELECT PAYMENT TERMS"
                                                                    error={errors?.payment_term}
                                                                    style={{ pointerEvents: mode == "overview" && "none" }}
                                                                >
                                                                    <ListSubheader style={{ color: grey }} disableSticky={true}>
                                                                        Non-VCL Category
                                                                    </ListSubheader>
                                                                    {
                                                                        AllPaymentTerms.map((item) => {
                                                                            if (!item.is_vcl_enabled) {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={item.id}
                                                                                        value={item.payment_method_name}
                                                                                    >
                                                                                        {item.payment_method_name}
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    <ListSubheader style={{ color: grey }} disableSticky={true}>
                                                                        VCL Category
                                                                    </ListSubheader>
                                                                    {
                                                                        AllPaymentTerms.map((item) => {
                                                                            if (item.is_vcl_enabled) {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={item.id}
                                                                                        value={item.payment_method_name}
                                                                                    >
                                                                                        {item.payment_method_name}
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Select>
                                                                {
                                                                    subcontractData.payment_term_details &&
                                                                    <FormHelperText>
                                                                        {
                                                                            AllPaymentTerms.some((item) => item.payment_method_name === subcontractData.payment_term_details?.payment_method_name && !item.is_vcl_enabled)
                                                                                ? "Non-VCL Category" : "VCL Category"
                                                                        }
                                                                    </FormHelperText>
                                                                }
                                                                {
                                                                    errors?.payment_term &&
                                                                    <FormHelperText error>
                                                                        {errors.payment_term}
                                                                    </FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className={classes.dataDisplay}>
                                                        <div>
                                                            <FormControl
                                                                variant="outlined"
                                                                className={classes.formControl}
                                                                size="small"
                                                            >
                                                                <TextField
                                                                    type="number"
                                                                    size="small"
                                                                    name="subContract_quantity"
                                                                    id="subContract_quantity"
                                                                    label="SUBCONTRACT QUANTITY*"
                                                                    variant="outlined"
                                                                    inputRef={sInputRef}
                                                                    placeholder="SUBCONTRACT QUANTITY*"
                                                                    value={subcontractData?.subContract_quantity || ""}
                                                                    onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    style={{ pointerEvents: mode == "overview" && "none" }}
                                                                    onChange={e => {
                                                                        setSubcontractData(p => ({ ...p, subContract_quantity: e.target.value }))
                                                                        setIsSubcontractQtyValid(true)
                                                                        setErrors({ ...errors, subContract_quantity: "" })
                                                                        if (mode == 'editSubContracts') {
                                                                            setNewAvailableQuantity(Number(oldSubcontractQuantity.current) - Number(e.target.value) + Number(oldAvailableQuantity.current))
                                                                        } else {
                                                                            setNewAvailableQuantity(oldAvailableQuantity.current)
                                                                        }

                                                                        if (Number(e.target.value) < totalAllottedRef.current || 0) {
                                                                            setIsTotalItemValueValid(false)
                                                                        } else {
                                                                            setIsTotalItemValueValid(true)
                                                                        }
                                                                        subContractAvailableQuantity.current = Number(e.target.value) - itemDetailsData.reduce((acc, curr) => Number(acc) + Number(curr.allotted_qty), 0)
                                                                    }}
                                                                    error={errors.subContract_quantity}
                                                                    helperText={errors.subContract_quantity}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    subcontractData.payment_term_details
                                                        && AllPaymentTerms.some((item) => item.payment_method_name === subcontractData.payment_term_details?.payment_method_name && item.is_vcl_enabled) ?
                                                        <Box sx={{ border: '2px dashed grey' }} style={{ width: '100%', borderRadius: "5px", padding: '7px' }} component="fieldset">
                                                            <legend style={{ marginLeft: "15px", color: "grey" }}>PAYMENT DETAILS</legend>
                                                            <div className={classes.contractDisplaySection}>
                                                                <div className={classes.dataDisplay}>
                                                                    {/* <div> */}
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        className={classes.formControl}
                                                                        size="small"
                                                                    >
                                                                        <TextField
                                                                            type="text"
                                                                            name="payment_doc_number"
                                                                            size="small"
                                                                            id="payment_doc_number"
                                                                            label="PAYMENT DOC#*"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            disabled={mode == 'editSubContracts'}
                                                                            value={subcontractData?.payment_doc_number}
                                                                            style={{ pointerEvents: mode == "overview" && "none" }}
                                                                            placeholder="PAYMENT DOC NUMBER"
                                                                            onChange={e => {
                                                                                setSubcontractData(p => ({ ...p, payment_doc_number: e.target.value }))
                                                                                setErrors({ ...errors, payment_doc_number: "" })
                                                                            }}
                                                                            error={errors.payment_doc_number}
                                                                            helperText={errors.payment_doc_number}
                                                                        />
                                                                    </FormControl>
                                                                    {/* </div> */}
                                                                </div>

                                                                <div className={classes.dataDisplay} style={{ paddingRight: '10px' }}>
                                                                    <div>
                                                                        <div style={{ paddingTop: "15px" }}>
                                                                            <FormControl
                                                                                variant="outlined"
                                                                                className={classes.formControl}
                                                                                size="small"
                                                                            >
                                                                                <Fragment>
                                                                                    <ThemeProvider theme={materialTheme}>
                                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                            <KeyboardDatePicker
                                                                                                name="payment_doc_date"
                                                                                                size="small"
                                                                                                inputVariant="outlined"
                                                                                                label="PAYMENT DOC DATE* "
                                                                                                format="dd/MM/yyyy"
                                                                                                disabled={mode == 'editSubContracts'}
                                                                                                value={subcontractData?.payment_doc_date || null}
                                                                                                onChange={(value) => {
                                                                                                    setSubcontractData(p => ({ ...p, payment_doc_date: value }))
                                                                                                    setErrors({ ...errors, payment_doc_date: "", payment_doc_valid_upto: "" })
                                                                                                }}
                                                                                                placeholder="DD/MM/YYYY"
                                                                                                InputAdornmentProps={{ position: "start" }}
                                                                                                InputProps={{ className: classes.datePicker, readOnly: false }}
                                                                                                error={errors.payment_doc_date}
                                                                                                helperText={errors.payment_doc_date}
                                                                                                style={{ pointerEvents: mode == "overview" && "none" }}
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </ThemeProvider>
                                                                                </Fragment>
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.contractDisplaySection}>
                                                                <div className={classes.dataDisplay} style={{ paddingTop: "15px" }}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                        >
                                                                            <InputLabel id="payment_bank">
                                                                                SELECT PAYMENT BANK NAME*
                                                                            </InputLabel>
                                                                            <Select
                                                                                id="payment_bank"
                                                                                name="payment_bank"
                                                                                disabled={mode == 'editSubContracts'}
                                                                                value={subcontractData.payment_bank_details?.bank_name}
                                                                                defaultValue={subcontractData.payment_bank_details?.bank_name}
                                                                                onChange={e => {
                                                                                    const bankDetails = bankMasterList.find(b => b.bank_name == e.target.value)
                                                                                    setSubcontractData(p => ({ ...p, payment_bank_details: bankDetails }))
                                                                                    setErrors({ ...errors, payment_bank: "" })
                                                                                }}
                                                                                MenuProps={MenuProps}
                                                                                label="SELECT PAYMENT BANK NAME*"
                                                                                error={errors.payment_bank}
                                                                                style={{ pointerEvents: mode == "overview" && "none" }}
                                                                            >
                                                                                {
                                                                                    bankMasterList.map((item) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={item.id}
                                                                                                value={item.bank_name}
                                                                                            >
                                                                                                {item.bank_name}
                                                                                            </MenuItem>
                                                                                        )

                                                                                    })
                                                                                }
                                                                            </Select>
                                                                            {
                                                                                errors?.payment_bank &&
                                                                                <FormHelperText error>
                                                                                    {errors.payment_bank}
                                                                                </FormHelperText>
                                                                            }
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay} style={{ paddingRight: '10px' }}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                        >
                                                                            <TextField
                                                                                type="number"
                                                                                size="small"
                                                                                name="payment_doc_value"
                                                                                id="payment_doc_value"
                                                                                label="PAYMENT DOC VALUE*"
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                inputRef={pInputRef}
                                                                                style={{ pointerEvents: mode == "overview" && "none" }}
                                                                                value={subcontractData.payment_doc_value}
                                                                                onChange={e => {
                                                                                    setSubcontractData(p => ({ ...p, payment_doc_value: e.target.value }))
                                                                                    setErrors({ ...errors, payment_doc_value: "" })
                                                                                    setHasItemError(false)
                                                                                    if (Number(e.target.value) && isVclEnabledRef.current) {
                                                                                        if (Number(e.target.value) < totalValueRef.current) {
                                                                                            setIsTotalValueValid(false)
                                                                                            console.log("8")
                                                                                            setHasItemError(true)
                                                                                        } else {
                                                                                            setIsTotalValueValid(true)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                error={errors.payment_doc_value}
                                                                                helperText={errors.payment_doc_value}
                                                                            />

                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.contractDisplaySection}>
                                                                <div className={classes.dataDisplay}>
                                                                    <div>
                                                                        <div style={{ paddingTop: "15px" }}>
                                                                            <FormControl
                                                                                variant="outlined"
                                                                                className={classes.formControl}
                                                                                size="small"
                                                                            >
                                                                                <Fragment>
                                                                                    <ThemeProvider theme={materialTheme}>
                                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                            <KeyboardDatePicker
                                                                                                name="payment_doc_valid_upto"
                                                                                                size="small"
                                                                                                inputVariant="outlined"
                                                                                                label="PAYMENT DOC VALID UPTO* "
                                                                                                format="dd/MM/yyyy"
                                                                                                disablePast
                                                                                                value={subcontractData?.payment_doc_valid_upto || null}
                                                                                                style={{ pointerEvents: mode == "overview" && "none" }}
                                                                                                onChange={(value) => {
                                                                                                    setSubcontractData(p => ({ ...p, payment_doc_valid_upto: value }))
                                                                                                    setErrors({ ...errors, payment_doc_valid_upto: "" })
                                                                                                }}
                                                                                                placeholder="DD/MM/YYYY"
                                                                                                InputAdornmentProps={{ position: "start" }}
                                                                                                InputProps={{ className: classes.datePicker, readOnly: false }}
                                                                                                error={errors.payment_doc_valid_upto}
                                                                                                helperText={errors.payment_doc_valid_upto}
                                                                                            />
                                                                                        </MuiPickersUtilsProvider>
                                                                                    </ThemeProvider>
                                                                                </Fragment>
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay} style={{ paddingRight: '10px' }}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                        >
                                                                            <TextField
                                                                                type="text"
                                                                                size="small"
                                                                                name="payment_doc_remark"
                                                                                id="payment_doc_remark"
                                                                                label="PAYMENT DOC REMARK"
                                                                                multiline
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                inputRef={pInputRef}
                                                                                value={subcontractData.payment_doc_remark}
                                                                                style={{ pointerEvents: mode == "overview" && "none" }}
                                                                                onChange={e => {
                                                                                    setSubcontractData(p => ({ ...p, payment_doc_remark: e.target.value }))
                                                                                    setErrors({ ...errors, payment_doc_remark: "" })
                                                                                }}
                                                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                error={errors.payment_doc_remark}
                                                                                helperText={errors.payment_doc_remark}
                                                                            />

                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                        : null}
                                                {mode == "overview" ? "" :
                                                    <div className={classes.displayFlex}>
                                                        <Button
                                                            size="medium"
                                                            className={classes.saveButton}
                                                            variant="contained"
                                                            startIcon={<CheckOutlinedIcon />}
                                                            type="submit"
                                                            onClick={() => {
                                                                const err = paymentTermsValidate();
                                                                if (Object.keys(err).length == 0 && !Boolean(partyRefUpload.partyRefFileTypeError)) {
                                                                    handleNext();
                                                                }
                                                            }}
                                                        >
                                                            CONTINUE
                                                        </Button>
                                                    </div>
                                                }

                                            </StepContent>
                                        </Step>

                                        <Step key="LADetails"
                                            expanded={mode == "overview"}
                                        >
                                            <StyledStepLabel
                                                StepIconProps={{
                                                    classes: {
                                                        root: classes.stepIconRoot,
                                                        labelContainer: classes.labelContainer,
                                                    },
                                                }}
                                            >
                                                DETAILS FOR LOADING ADVICES
                                            </StyledStepLabel>
                                            <StepContent>
                                                <Formik
                                                >
                                                    {(formik) => (
                                                        <Form>
                                                            <div className={classes.generalDetailsDisplaySection}>
                                                                <div className={classes.dataDisplay}>
                                                                    <div className={classes.dataFieldsForColumn}>
                                                                        <Autocomplete
                                                                            autoSelect
                                                                            options={
                                                                                buyerSelectionList.length > 0 ? buyerSelectionList : []
                                                                            }
                                                                            getOptionLabel={(option) =>
                                                                                option !== 'Select Buyer' && option.first_name + " " + option.last_name
                                                                            }
                                                                            style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                            value={!isEmpty(additionalData)
                                                                                ? additionalData
                                                                                : "Select Buyer"}
                                                                            onChange={(e, v) => {
                                                                                setAdditionalData(v)
                                                                                setSubcontractData(p => ({ ...p, buyer: v?.id }))
                                                                                let cityPincodeData = categoryCityDiscountData?.find((city) =>
                                                                                    city?.pincodes.length > 0
                                                                                        ? city?.pincodes.includes(
                                                                                            city?.pincodes.find((el) => el.pincode === Number(v?.address?.pincode))
                                                                                        )
                                                                                        : false
                                                                                );
                                                                                setCityPincodeData(cityPincodeData)

                                                                            }}
                                                                            disabled={!(subContractDetails?.dispatch_detail?.sub_contract_qty == subContractDetails?.dispatch_detail?.available_qty)}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="SEARCH BUYER"
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                //helperText={editData.buyer}
                                                                                //style={{ pointerEvents: this.props.overview && "none" }}
                                                                                />
                                                                            )}
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                        />
                                                                        {
                                                                            !isEmpty(additionalData) ?
                                                                                <div
                                                                                    className={classes.additionalData}
                                                                                    style={{ width: '100%', margin: 5, padding: '0 5px' }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                width: "30%",
                                                                                                fontWeight: "bold",
                                                                                                color: grey,
                                                                                            }}
                                                                                        >
                                                                                            NAME:{" "}
                                                                                        </span>
                                                                                        <span style={{ width: "70%" }}>
                                                                                            {additionalData?.first_name || ""}{" "}
                                                                                            {additionalData?.last_name || ""}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                width: "30%",
                                                                                                fontWeight: "bold",
                                                                                                color: grey,
                                                                                            }}
                                                                                        >
                                                                                            {!additionalData?.gstin_number
                                                                                                ? !additionalData
                                                                                                    ?.pan_card_number
                                                                                                    ? ""
                                                                                                    : "PAN: "
                                                                                                : "GSTIN: "}
                                                                                        </span>
                                                                                        <span style={{ width: "70%" }}>
                                                                                            {!additionalData?.gstin_number
                                                                                                ? !additionalData
                                                                                                    ?.pan_card_number
                                                                                                    ? ""
                                                                                                    : additionalData
                                                                                                        ?.pan_card_number
                                                                                                : additionalData?.gstin_number}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                width: "30%",
                                                                                                fontWeight: "bold",
                                                                                                color: grey,
                                                                                            }}
                                                                                        >
                                                                                            PHONE:{" "}
                                                                                        </span>
                                                                                        <span style={{ width: "70%" }}>
                                                                                            {additionalData?.phone || ""}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            marginBottom: "10px",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                width: "30%",
                                                                                                fontWeight: "bold",
                                                                                                color: grey,
                                                                                            }}
                                                                                        >
                                                                                            ADDRESS:{" "}
                                                                                        </span>
                                                                                        <span style={{ width: "70%" }}>
                                                                                            {!isEmpty(additionalData) ? (
                                                                                                (
                                                                                                    additionalData?.address?.address ?
                                                                                                        (
                                                                                                            (additionalData?.address?.address
                                                                                                                ? additionalData?.address?.address + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.city
                                                                                                                ? additionalData?.address?.city + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.state
                                                                                                                ? additionalData?.address?.state + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.country
                                                                                                                ? additionalData?.address?.country + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.pincode
                                                                                                                ? additionalData?.address?.pincode
                                                                                                                : "")
                                                                                                        )
                                                                                                        :
                                                                                                        (
                                                                                                            (additionalData?.address?.door_number
                                                                                                                ? additionalData?.address?.door_number + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.floor_number
                                                                                                                ? 'Floor No - ' + additionalData?.address?.floor_number + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.building_name
                                                                                                                ? additionalData?.address?.building_name + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.street
                                                                                                                ? additionalData?.address?.street + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.location
                                                                                                                ? additionalData?.address?.location + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.district_name
                                                                                                                ? additionalData?.address?.district_name + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            ((additionalData?.address?.city_name && additionalData?.buyer_details?.address?.district_name)
                                                                                                                ? ((additionalData?.address?.city_name).trim().toUpperCase() !== (additionalData?.buyer_details?.address?.district_name).trim().toUpperCase())
                                                                                                                    ? additionalData?.address?.city_name + ", "
                                                                                                                    : ""
                                                                                                                : additionalData?.address?.city_name
                                                                                                                    ? additionalData?.address?.city_name + ", "
                                                                                                                    : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.state_name
                                                                                                                ? additionalData?.address?.state_name + ", "
                                                                                                                : "")
                                                                                                            +
                                                                                                            (additionalData?.address?.pincode
                                                                                                                ? additionalData?.address?.pincode
                                                                                                                : "")
                                                                                                        )
                                                                                                )
                                                                                            )
                                                                                                :
                                                                                                ""
                                                                                            }


                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                                                    <div className={classes.dataFieldsForColumn}>
                                                                        <Autocomplete
                                                                            autoSelect
                                                                            options={
                                                                                allConsigneeList.length > 0 ? allConsigneeList : []
                                                                            }
                                                                            getOptionLabel={(option) =>
                                                                                option !== 'Select Consignee' && (
                                                                                    (option?.consignee_detail?.first_name || "") +
                                                                                    " " +
                                                                                    (option?.consignee_detail?.last_name || "") +
                                                                                    ", " +
                                                                                    (
                                                                                        option?.consignee_address?.address ?
                                                                                            (
                                                                                                (option?.consignee_address?.address
                                                                                                    ? option?.consignee_address?.address + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.city
                                                                                                    ? option?.consignee_address?.city + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.state
                                                                                                    ? option?.consignee_address?.state + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.country
                                                                                                    ? option?.consignee_address?.country + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.pincode
                                                                                                    ? option?.consignee_address?.pincode
                                                                                                    : "")
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                (option?.consignee_address?.door_number
                                                                                                    ? option?.consignee_address?.door_number + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.floor_number
                                                                                                    ? 'Floor No - ' + option?.consignee_address?.floor_number + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.building_name
                                                                                                    ? option?.consignee_address?.building_name + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.street
                                                                                                    ? option?.consignee_address?.street + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.location
                                                                                                    ? option?.consignee_address?.location + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.district_name
                                                                                                    ? option?.consignee_address?.district_name + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                ((option?.consignee_address?.city_name && option?.consignee_address?.district_name)
                                                                                                    ? ((option?.consignee_address?.city_name).trim().toUpperCase() !== (option?.consignee_address?.district_name).trim().toUpperCase())
                                                                                                        ? option?.consignee_address?.city_name + ", "
                                                                                                        : ""
                                                                                                    : option?.consignee_address?.city_name
                                                                                                        ? option?.consignee_address?.city_name + ", "
                                                                                                        : "")
                                                                                                +
                                                                                                (option?.consignee_address?.state_name
                                                                                                    ? option?.consignee_address?.state_name + ", "
                                                                                                    : "")
                                                                                                +
                                                                                                (option?.consignee_address?.pincode
                                                                                                    ? option?.consignee_address?.pincode
                                                                                                    : "")
                                                                                            )
                                                                                    ))
                                                                            }
                                                                            style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                            value={!isEmpty(consigneeData)
                                                                                ? consigneeData
                                                                                : "Select Consignee"}
                                                                            onChange={(e, v) => {
                                                                                setConsigneeData(v);
                                                                                setSubcontractData(p => ({ ...p, consignee: v?.id }))
                                                                            }}
                                                                            disabled={!(subContractDetails?.dispatch_detail?.sub_contract_qty == subContractDetails?.dispatch_detail?.available_qty)}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="SEARCH CONSIGNEE"
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                //helperText={editData.buyer}
                                                                                //style={{ pointerEvents: this.props.overview && "none" }}
                                                                                />
                                                                            )}
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                        />
                                                                        {!isEmpty(consigneeData) ?
                                                                            <div
                                                                                className={classes.additionalData}
                                                                                style={{ width: '100%', margin: 5, padding: '0 5px' }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            width: "30%",
                                                                                            fontWeight: "bold",
                                                                                            color: grey,
                                                                                        }}
                                                                                    >
                                                                                        NAME:{" "}
                                                                                    </span>
                                                                                    <span style={{ width: "70%" }}>
                                                                                        {consigneeData
                                                                                            ?.consignee_detail?.first_name || ""}{" "}
                                                                                        {consigneeData
                                                                                            ?.consignee_detail?.last_name || ""}
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            width: "30%",
                                                                                            fontWeight: "bold",
                                                                                            color: grey,
                                                                                        }}
                                                                                    >
                                                                                        {!consigneeData
                                                                                            ?.consignee_detail?.gstin_number
                                                                                            ? !consigneeData
                                                                                                ?.consignee_detail?.pan_card_number
                                                                                                ? ""
                                                                                                : "PAN: "
                                                                                            : "GSTIN: "}
                                                                                    </span>
                                                                                    <span style={{ width: "70%" }}>
                                                                                        {!consigneeData
                                                                                            ?.consignee_detail?.gstin_number
                                                                                            ? !consigneeData
                                                                                                ?.consignee_detail?.pan_card_number
                                                                                                ? ""
                                                                                                : consigneeData
                                                                                                    ?.consignee_detail?.pan_card_number
                                                                                            : consigneeData
                                                                                                ?.consignee_detail?.gstin_number}
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            width: "30%",
                                                                                            fontWeight: "bold",
                                                                                            color: grey,
                                                                                        }}
                                                                                    >
                                                                                        PHONE:{" "}
                                                                                    </span>
                                                                                    <span style={{ width: "70%" }}>
                                                                                        {consigneeData
                                                                                            ?.consignee_detail?.phone || ""}
                                                                                    </span>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            width: "30%",
                                                                                            fontWeight: "bold",
                                                                                            color: grey,
                                                                                        }}
                                                                                    >
                                                                                        ADDRESS:{" "}
                                                                                    </span>
                                                                                    <span style={{ width: "70%" }}>

                                                                                        {!isEmpty(consigneeData) ? (
                                                                                            (
                                                                                                consigneeData?.consignee_address?.address ?
                                                                                                    (
                                                                                                        (consigneeData?.consignee_address?.address
                                                                                                            ? consigneeData?.consignee_address?.address + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.city
                                                                                                            ? consigneeData?.consignee_address?.city + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.state
                                                                                                            ? consigneeData?.consignee_address?.state + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.country
                                                                                                            ? consigneeData?.consignee_address?.country + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.pincode
                                                                                                            ? consigneeData?.consignee_address?.pincode
                                                                                                            : "")
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        (consigneeData?.consignee_address?.door_number
                                                                                                            ? consigneeData?.consignee_address?.door_number + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.floor_number
                                                                                                            ? 'Floor No - ' + consigneeData?.consignee_address?.floor_number + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.building_name
                                                                                                            ? consigneeData?.consignee_address?.building_name + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.street
                                                                                                            ? consigneeData?.consignee_address?.street + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.location
                                                                                                            ? consigneeData?.consignee_address?.location + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.district_name
                                                                                                            ? consigneeData?.consignee_address?.district_name + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        ((consigneeData?.consignee_address?.city_name && consigneeData?.consignee_address?.district_name)
                                                                                                            ? ((consigneeData?.consignee_address?.city_name).trim().toUpperCase() !== (consigneeData?.consignee_address?.district_name).trim().toUpperCase())
                                                                                                                ? consigneeData?.consignee_address?.city_name + ", "
                                                                                                                : ""
                                                                                                            : consigneeData?.consignee_address?.city_name
                                                                                                                ? consigneeData?.consignee_address?.city_name + ", "
                                                                                                                : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.state_name
                                                                                                            ? consigneeData?.consignee_address?.state_name + ", "
                                                                                                            : "")
                                                                                                        +
                                                                                                        (consigneeData?.consignee_address?.pincode
                                                                                                            ? consigneeData?.consignee_address?.pincode
                                                                                                            : "")
                                                                                                    )
                                                                                            )
                                                                                        )
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                            style={{ margin: isMobile && 0 }}
                                                                        >
                                                                            <InputLabel id="payment_method_selectLable">
                                                                                SELECT FREIGHT TYPE
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="fright"
                                                                                id="frightSelectLable"
                                                                                value={subcontractData.freight_basis_details?.id || ""}
                                                                                onChange={(e) => {
                                                                                    const _freightbasis = freightBasis.find(f => f.id === e.target.value);
                                                                                    setSubcontractData({
                                                                                        ...subcontractData, freight_basis_details: _freightbasis,
                                                                                        freight_charge_rate: _freightbasis?.name === "TO-PAY" ? 0 : Number(props.location?.state?.orderDetails?.freight_charge_percent)
                                                                                    })
                                                                                }}
                                                                                MenuProps={MenuProps}
                                                                                style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                label="Select Freight Type"
                                                                            >
                                                                                {
                                                                                    freightBasis.map((item) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={item.id}
                                                                                                value={item.id}
                                                                                            >
                                                                                                {item.name}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                            style={{ margin: isMobile && 0 }}
                                                                        >
                                                                            <TextField
                                                                                type="number"
                                                                                name="cessAmount"
                                                                                id="outlined-name"
                                                                                label="CESS RATE"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={subcontractData.cess_rate}
                                                                                style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                onChange={(e) => {
                                                                                    setSubcontractData({ ...subcontractData, cess_rate: e.target.value })
                                                                                }}
                                                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                // error={props?.quantityError}
                                                                                // FormHelperTextProps={{ className: classes.helperText }}
                                                                                // helperText={props?.quantityError ? "Input valid quantity" : null}
                                                                                InputProps={{
                                                                                    pattern: "[0-9]*",
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <span style={{ fontSize: "0.9em", color: grey }}>Per MT</span>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay} style={{ paddingTop: "25px" }}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                            style={{ margin: isMobile && 0 }}
                                                                        >
                                                                            <TextField
                                                                                disabled={!subcontractData.freight_basis_details || subcontractData.freight_basis_details?.name === "TO-PAY"}
                                                                                type="number"
                                                                                name="freightCharge"
                                                                                id="outlined-name"
                                                                                label="FREIGHT CHARGE"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                value={subcontractData.freight_charge_rate}
                                                                                style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                onChange={(e) => {
                                                                                    setSubcontractData({ ...subcontractData, freight_charge_rate: e.target.value })
                                                                                }}
                                                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                // error={props?.quantityError}
                                                                                // FormHelperTextProps={{ className: classes.helperText }}
                                                                                helperText={subcontractData.freight_basis_details?.name === "TO-PAY" ? "Freight charge is applicable only in case of FOR" : null}
                                                                                InputProps={{
                                                                                    pattern: "[0-9]*",
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            <span style={{ fontSize: "0.9em", color: grey }}>Per MT</span>
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.dataDisplay} style={{ paddingTop: "25px" }}>
                                                                    <div>
                                                                        <FormControl
                                                                            variant="outlined"
                                                                            className={classes.formControl}
                                                                            size="small"
                                                                            style={{ margin: isMobile && 0 }}
                                                                        >
                                                                            <TextField
                                                                                type="number"
                                                                                name="freightAdvance"
                                                                                id="outlined-name"
                                                                                label={subcontractData.freight_basis_details === "FOR" ? "TOTAL FREIGHT ADVANCE LIMIT(IN PERCENTAGE)" :
                                                                                    subcontractData.freight_basis_details === "TO-PAY" ? "TOTAL FREIGHT ADVANCE LIMIT(IN AMOUNT)" : "TOTAL FREIGHT ADVANCE LIMIT"}
                                                                                variant="outlined"
                                                                                size="small"
                                                                                helperText={"Actual freight advance will be entered in each LA. Total freight advance of all LAs cannot be more than this limit."}
                                                                                value={subcontractData?.freight_advance}
                                                                                style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                onChange={(e) => {
                                                                                    subcontractData.freight_basis_details?.name === "FOR" && Number(e.target.value) > Number(100)
                                                                                        ? e.preventDefault()
                                                                                        : setSubcontractData({ ...subcontractData, freight_advance: e.target.value })
                                                                                }}
                                                                                onKeyDown={(e) => (e.which === 38 || e.which === 40) && e.preventDefault()}
                                                                                onWheel={(e) => e.target.blur()}
                                                                                // error={props?.quantityError}
                                                                                // FormHelperTextProps={{ className: classes.helperText }}
                                                                                // helperText={props?.quantityError ? "Input valid quantity" : null}
                                                                                InputProps={{
                                                                                    pattern: "[0-9]*",
                                                                                    endAdornment: (
                                                                                        <InputAdornment position="end">
                                                                                            {subcontractData.freight_basis_details?.name === "FOR" ?
                                                                                                <span style={{ fontSize: "0.9em", color: grey }}>%</span> :
                                                                                                subcontractData.freight_basis_details?.name === "TO-PAY" ?
                                                                                                    <span style={{ fontSize: "0.9em", color: grey }}>₹</span> :
                                                                                                    <span style={{ fontSize: "0.9em", color: grey }}>Per MT</span>}
                                                                                        </InputAdornment>
                                                                                    ),
                                                                                }}

                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {mode == "overview" ? "" :
                                                                <div className={classes.displayFlex}>
                                                                    <Button
                                                                        size="medium"
                                                                        className={classes.saveButton}
                                                                        variant="contained"
                                                                        startIcon={<CheckOutlinedIcon />}
                                                                        onClick={handleNext}
                                                                        disabled={
                                                                            isEmpty(additionalData) ||
                                                                            isEmpty(consigneeData) ||
                                                                            isEmpty(subcontractData?.freight_basis_details)
                                                                        }
                                                                    >
                                                                        CONTINUE
                                                                    </Button>
                                                                    <Button
                                                                        size="medium"
                                                                        onClick={handleBack}
                                                                        className={classes.backButton}
                                                                    >
                                                                        BACK
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </StepContent>
                                        </Step>

                                        <Step key="PlanItems"
                                            expanded={mode == "overview"}
                                        >
                                            <StyledStepLabel
                                                StepIconProps={{
                                                    classes: {
                                                        root: classes.stepIconRoot,
                                                        labelContainer: classes.labelContainer,
                                                    },
                                                }}
                                            >
                                                PLAN ITEMS
                                            </StyledStepLabel>
                                            <StepContent>
                                                <>
                                                    <div className={classes.generalDetailsDisplaySection} style={{ flexDirection: isMobile && 'column', }}>
                                                        <div className={classes.dataDisplay} style={{ width: isMobile && '100%' }}>
                                                            <div className={classes.dataFields}>
                                                                <Typography className={classes.titleLabel} style={{ width: isMobile && '100%' }}>
                                                                    AVAILABLE QUANTITY
                                                                </Typography>
                                                                <Typography className={classes.formControl}>
                                                                    {Number(subContractAvailableQuantity.current).toFixed(3)} MT
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className={classes.additionalDataDisplay}>
                                                            <Typography className={classes.titleLabelStyle}>
                                                                {" "}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <ThemeProvider theme={theme}>
                                                        <Paper>
                                                            <TableContainer
                                                                className={classes.tableContainer}
                                                            >
                                                                <Table className={classes.table} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow className={classes.tableHeader}>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '20%'
                                                                                }}
                                                                            >
                                                                                Item Name
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '10%'
                                                                                }}
                                                                            >
                                                                                Item Code
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '15%'
                                                                                }}
                                                                            >
                                                                                Allotted Quantity
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '20%'
                                                                                }}
                                                                            >
                                                                                Item Remark
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '15%'
                                                                                }}
                                                                            // onMouseEnter={handlePopoverOpen}
                                                                            // onMouseLeave={handlePopoverClose}
                                                                            // onClick={handlePopoverOpen}
                                                                            >
                                                                                Total Value
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '10%'
                                                                                }}
                                                                            >
                                                                                <div className={classes.alignText}>
                                                                                    <div className={classes.rightSpacing}>LA Quantity</div>
                                                                                    <div>
                                                                                        <Tooltip placement='top'
                                                                                            title="Sum of all LA Quantity created for this item in this subcontract" arrow>
                                                                                            <InfoOutlined
                                                                                                className={classes.arrowDimension}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="center"
                                                                                style={{
                                                                                    color: grey,
                                                                                    fontSize: "0.9em",
                                                                                    fontWeight: "bold",
                                                                                    width: '10%'
                                                                                }}
                                                                            >
                                                                                Open Quantity
                                                                            </TableCell>
                                                                            {mode == "overview" ? "" :
                                                                                <TableCell
                                                                                    align="center"
                                                                                    style={{
                                                                                        color: grey,
                                                                                        fontSize: "0.9em",
                                                                                        fontWeight: "bold",
                                                                                        width: '20%'
                                                                                    }}
                                                                                >
                                                                                    {/* Action */}
                                                                                </TableCell>
                                                                            }
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            itemDetailsData?.map((itemData, index) => (
                                                                                <TableRow key={index} style={{ backgroundColor: '#FFF' }}>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        style={{
                                                                                            fontSize: "0.85em",
                                                                                            width: '20%'
                                                                                        }}
                                                                                    >
                                                                                        <FormControl
                                                                                            variant="outlined"
                                                                                            className={classes.formControl}
                                                                                            size="small"
                                                                                        >
                                                                                            <Autocomplete
                                                                                                name="item"
                                                                                                required
                                                                                                options={
                                                                                                    allItemsList.filter(a => itemDetailsData.find(l => l.item == a?.id) == undefined)
                                                                                                }
                                                                                                disabled={itemDetailsData[index]?.la_qty > 0}
                                                                                                onChange={(e, v) => {
                                                                                                    const items = [...itemDetailsData]
                                                                                                    const selectedItem = items[index]
                                                                                                    selectedItem.item = v?.id
                                                                                                    selectedItem.item_code = v?.item_code
                                                                                                    selectedItem.item_name = v?.item_name
                                                                                                    selectedItem.items_nature = v?.items_nature
                                                                                                    selectedItem.rate_difference = getRateDiff(v)
                                                                                                    if (selectedItem.allotted_qty) {
                                                                                                        handleAllottedQuantityChange(Number(selectedItem.allotted_qty), index)
                                                                                                    }
                                                                                                    setItemDetailsData(items)

                                                                                                    if (selectedItemId?.current != v?.id && index == 0 && v?.id) {
                                                                                                        selectedItemId.current = v?.id
                                                                                                        if (mode == "addSubContracts") {
                                                                                                            handleFetchTcsRateData(additionalData?.buyerid, selectedItemId.current)
                                                                                                        }
                                                                                                    }
                                                                                                    if (subcontractData.sub_contract_items) {
                                                                                                        const _subContractItems = subcontractData.sub_contract_items
                                                                                                        const _subContractItem = _subContractItems[index]
                                                                                                        if (_subContractItem) {
                                                                                                            _subContractItem.item = v?.id
                                                                                                            const existingItem = receivedSubContractItems.find(r => r.id == _subContractItem.id)
                                                                                                            if (existingItem && existingItem.item == v?.id) {
                                                                                                                _subContractItem.method = UNCHANGE
                                                                                                            } else {
                                                                                                                _subContractItem.method = UPDATED
                                                                                                            }
                                                                                                            setSubcontractData({ ...subcontractData, sub_contract_items: _subContractItems })
                                                                                                        } else {
                                                                                                            const _newSubContractItem = { item: v?.id, allotted_qty: null, item_value: 0.0, remark: null, id: null, method: ADDED }
                                                                                                            _subContractItems.push(_newSubContractItem)
                                                                                                            setSubcontractData({ ...subcontractData, sub_contract_items: _subContractItems })
                                                                                                        }
                                                                                                    } else {
                                                                                                        const _subContractItems = []
                                                                                                        const _subContractItem = { item: v?.id, allotted_qty: null, item_value: 0.0 }
                                                                                                        _subContractItems.push(_subContractItem)
                                                                                                        setSubcontractData({ ...subcontractData, sub_contract_items: _subContractItems })
                                                                                                    }
                                                                                                }}
                                                                                                style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                                getOptionLabel={(option) => (option.item_name ? `${option.item_name} - (${option.items_nature})` : "")}
                                                                                                value={!isEmpty(itemDetailsData[index])
                                                                                                    ? allItemsListMaster.find(a => a.id == itemDetailsData[index]?.item)
                                                                                                    : "Select Item"}
                                                                                                sx={{ width: 300 }}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    label="SELECT ITEM *"
                                                                                                    variant="outlined"
                                                                                                    error={itemData?.itemError}
                                                                                                    size="small" />}
                                                                                            />
                                                                                        </FormControl>

                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            //color: grey,
                                                                                            fontSize: "0.85em",
                                                                                            width: '5%'
                                                                                        }}
                                                                                    // index={index}
                                                                                    >
                                                                                        {itemData?.item_code || ""}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            //color: grey,
                                                                                            fontSize: "0.85em",
                                                                                            width: '13%'
                                                                                        }}
                                                                                    >
                                                                                        <TextField
                                                                                            type="number"
                                                                                            className={classes.formControl}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            name="allotted_qty"
                                                                                            //label="ALLOTTED QUANTITY*"
                                                                                            value={itemDetailsData[index]?.allotted_qty || ""}
                                                                                            style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                            disabled={!!!itemDetailsData[index]?.item}
                                                                                            onChange={(e) => {
                                                                                                handleAllottedQuantityChange(e.target.value, index)
                                                                                            }}

                                                                                            InputProps={{
                                                                                                endAdornment: (
                                                                                                    <InputAdornment position="start">
                                                                                                        MT
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                            }}
                                                                                            error={itemDetailsData[index]?.hasAllottedQtyErr}
                                                                                            helperText={itemDetailsData[index]?.hasAllottedQtyErr && itemDetailsData[index]?.AllottedQtyErr}
                                                                                        />

                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            //color: grey,
                                                                                            fontSize: "0.85em",
                                                                                            width: '18%'
                                                                                        }}
                                                                                    >
                                                                                        <TextField
                                                                                            name="remark"
                                                                                            label="ITEM REMARKS"
                                                                                            className={classes.formControl}
                                                                                            value={itemDetailsData[index]?.remark || ""}
                                                                                            style={{ pointerEvents: mode == "overview" && "none", margin: isMobile && 0 }}
                                                                                            disabled={!!!itemDetailsData[index]?.item}
                                                                                            onChange={(e) => {
                                                                                                const items = [...itemDetailsData]
                                                                                                items[index].remark = e.target.value;
                                                                                                const subContractItems = subcontractData.sub_contract_items
                                                                                                const subContractItem = subContractItems[index]
                                                                                                subContractItem.remark = e.target.value
                                                                                                const existingItem = receivedSubContractItems.find(r => r.id == subContractItem.id)
                                                                                                if (existingItem && existingItem.remark == e.target.value) {
                                                                                                    subContractItem.method = UNCHANGE
                                                                                                } else {
                                                                                                    if (existingItem) {
                                                                                                        subContractItem.method = UPDATED
                                                                                                    } else {
                                                                                                        subContractItem.method = ADDED
                                                                                                    }
                                                                                                }
                                                                                                setItemDetailsData(items)
                                                                                                setSubcontractData({ ...subcontractData, sub_contract_items: subContractItems })
                                                                                            }
                                                                                            }
                                                                                            multiline
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            fontSize: "0.85em",
                                                                                            width: '13%'
                                                                                        }}
                                                                                    >
                                                                                        <div className={classes.alignText}>
                                                                                            <div className={classes.rightSpacing}
                                                                                                style={{ fontFamily: "Poppins", fontWeight: 500 }}>
                                                                                                {(subcontractData?.sub_contract_items && itemDetailsData[index].allotted_qty) ? itemDetailsData[index]?.item_value : 0.00}
                                                                                                {/* 5.0 */}
                                                                                            </div>
                                                                                            {itemDetailsData[index]?.allotted_qty ?
                                                                                                <div
                                                                                                    // onMouseEnter={(event) => {
                                                                                                    //     setSelectedItemDetailsData(itemDetailsData[index])
                                                                                                    //     handlePopoverOpen(event)
                                                                                                    // }}
                                                                                                    onClick={(event) => {
                                                                                                        setSelectedItemDetailsData(itemDetailsData[index])
                                                                                                        handlePopoverOpen(event)
                                                                                                    }}>
                                                                                                    <InfoOutlined
                                                                                                        className={classes.arrowDimension2}
                                                                                                    />
                                                                                                </div>
                                                                                                : ""
                                                                                            }
                                                                                        </div>
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            fontSize: "0.85em",
                                                                                            width: '10%'
                                                                                        }}
                                                                                    >
                                                                                        {(mode == "editSubContracts" || mode == "overview") ? itemDetailsData[index]?.la_qty : ""}
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="center"
                                                                                        style={{
                                                                                            fontSize: "0.85em",
                                                                                            width: '10%',
                                                                                            fontFamily: "Poppins",
                                                                                            fontWeight: 500
                                                                                        }}
                                                                                    >
                                                                                        {(mode == "editSubContracts" || mode == "overview") ? Number(itemDetailsData[index]?.available_qty || 0).toFixed(3) : ""}
                                                                                    </TableCell>
                                                                                    {mode == "overview" ? "" :
                                                                                        <TableCell
                                                                                            align="center"
                                                                                            //index={index}
                                                                                            style={{ width: '10%' }}
                                                                                        >
                                                                                            <div>
                                                                                                <IconButton
                                                                                                    onClick={() => {
                                                                                                        setHasItemError(false)
                                                                                                        const newItems = itemDetailsData.filter((_, i) => i != index)
                                                                                                        const deletedItem = itemDetailsData.find((_, i) => i == index)
                                                                                                        const newSubContractData = { ...subcontractData }
                                                                                                        if (deletedItem?.method != ADDED) {
                                                                                                            newSubContractData.sub_contract_items.find((s) => s.item == deletedItem?.item).method = DELETED
                                                                                                        } else {
                                                                                                            newSubContractData.sub_contract_items =
                                                                                                                newSubContractData.sub_contract_items.filter((s) => s.item != deletedItem?.item)
                                                                                                        }
                                                                                                        const qty = newItems.map(item => Number(item.allotted_qty)).reduce((a, b) => a + b, 0)
                                                                                                        totalAllottedRef.current = Number(newItems?.map(item => Number(item.allotted_qty)).reduce((a, b) => a + b, 0)).toFixed(2)
                                                                                                        totalValueRef.current = Number(newItems?.map(item => Number(item.item_value)).reduce((a, b) => a + b, 0)).toFixed(2)
                                                                                                        subContractAvailableQuantity.current = Number(subcontractData?.subContract_quantity) - newItems.reduce((acc, curr) => Number(acc) + Number(curr.allotted_qty), 0)
                                                                                                        let noError = true
                                                                                                        if (newItems.length == 0) {
                                                                                                            noError = false
                                                                                                            setIsTotalValueValid(false)
                                                                                                            setIsTotalItemValueValid(false)
                                                                                                            setAllottedQtyValid(false)
                                                                                                        } else {
                                                                                                            if (Number(newSubContractData?.payment_doc_value) && isVclEnabledRef.current) {
                                                                                                                if (Number(newSubContractData?.payment_doc_value) < totalValueRef.current) {
                                                                                                                    noError = false
                                                                                                                    setIsTotalValueValid(false)
                                                                                                                } else {
                                                                                                                    setIsTotalValueValid(true)
                                                                                                                }
                                                                                                            }

                                                                                                            if (Number(newSubContractData?.subContract_quantity) < totalAllottedRef.current) {
                                                                                                                noError = false
                                                                                                                setIsTotalItemValueValid(false)
                                                                                                            } else {
                                                                                                                setIsTotalItemValueValid(true)
                                                                                                            }

                                                                                                            if ((originalQuantity - qty) < 0) {
                                                                                                                noError = false
                                                                                                                setAllottedQtyValid(false)
                                                                                                            } else {
                                                                                                                setAllottedQtyValid(true)
                                                                                                            }
                                                                                                        }
                                                                                                        setItemDetailsData(newItems)
                                                                                                        setAvaliableQty(originalQuantity - qty)
                                                                                                        setSubcontractData(newSubContractData)
                                                                                                    }}
                                                                                                    disabled={itemDetailsData[index]?.la_qty > 0}
                                                                                                >
                                                                                                    <DeleteIcon />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                    }
                                                                                </TableRow>


                                                                            ))

                                                                        }
                                                                    </TableBody>
                                                                    {itemDetailsData.length > 0 &&
                                                                        <TableFooter>
                                                                            <TableRow className={classes.tableHeader}>
                                                                                <TableCell align="center" className={classes.tableHead}>
                                                                                    Total
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>

                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>
                                                                                    {totalAllottedRef.current || 0.00}
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>

                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>
                                                                                    {totalValueRef.current || 0.00}
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>
                                                                                    {mode == "editSubContracts" ? Number(itemDetailsData?.map(item => Number(item.la_qty || 0)).reduce((a, b) => a + b, 0)).toFixed(3) || 0.000 : ""}
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.tableHead}>
                                                                                    {mode == "editSubContracts" ? Number(itemDetailsData?.map(item => Number(item.available_qty || 0)).reduce((a, b) => a + b, 0)).toFixed(3) || 0.000 : ""}
                                                                                </TableCell>
                                                                                {mode == "overview" ? "" :
                                                                                    <TableCell align="center" className={classes.tableHead}></TableCell>}
                                                                            </TableRow>
                                                                        </TableFooter>
                                                                    }
                                                                </Table>
                                                            </TableContainer>
                                                            <>
                                                                {
                                                                    selectedItemDetailsData && <div>
                                                                        <Popover
                                                                            open={open}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handlePopoverClose}
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'center',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            PaperProps={{
                                                                                style: {
                                                                                    width: "550px", // Adjust the maximum width as needed
                                                                                    overflowY: 'auto', // Enable horizontal scrolling
                                                                                },
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", justifyContent: "space-around", width: "100%", padding: "10px" }}>
                                                                                <div style={{ width: "50%" }}>
                                                                                    <Typography style={{ padding: '10px' }}>
                                                                                        <div className={classes.alignTextPopper}>
                                                                                            <div style={{ fontSize: "14px", fontFamily: "Poppins", color: "#000000", fontWeight: 500 }}>All Discounts</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Customer</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {discountData?.customer_discount || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>City</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {cityDiscount || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Payment Basis</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {paymentBasisDiscount || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Special Discount</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {discountData?.special_discount || 0.00}</div>
                                                                                        </div>
                                                                                        <Divider variant="fullWidth" />
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Total Discount</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}><b>₹ {getTotalDiscount()}</b></div>
                                                                                        </div>
                                                                                    </Typography>
                                                                                    <Divider variant="fullWidth" />
                                                                                    <Typography style={{ padding: '10px' }}>
                                                                                        <div className={classes.alignTextPopper}>
                                                                                            <div style={{ fontSize: "14px", fontFamily: "Poppins", color: "#000000", fontWeight: 500 }}>Total Discounted Rate</div>

                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Contract Rate</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {orderDetails?.rate}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Item Rate Difference</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>{selectedItemDetailsData?.rate_difference}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Payment Basis Premium</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {paymentMethodDiscount || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Loading Premium</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {orderDetails?.discounts?.loading_premium || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Insurance Premium</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {orderDetails?.discounts?.insurance_premium || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Freight Rate</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {subcontractData.freight_charge_rate || 0.00}</div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>All Discounts</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {getTotalDiscount()}</div>
                                                                                        </div>
                                                                                        <Divider variant="fullWidth" />
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Total Discounted Rate</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}><b>
                                                                                                {getTotalDiscountedRate(selectedItemDetailsData && selectedItemDetailsData?.rate_difference)} /MT</b></div>
                                                                                        </div>

                                                                                    </Typography>
                                                                                </div>
                                                                                <Divider variant="fullWidth" />
                                                                                <div style={{ width: "50%" }}>
                                                                                    <Typography style={{ padding: '10px' }}>
                                                                                        <div>
                                                                                            <div style={{ display: "flex" }}>
                                                                                                <div style={{ fontSize: "14px", fontFamily: "Poppins", color: "#000000", fontWeight: 500 }}>Approximate Amount</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>{selectedItemDetailsData?.item_name || ""}<br /><span style={{ color: "grey", fontStyle: "italic", fontSize: "10px" }}>
                                                                                                ({getTotalDiscountedRate(selectedItemDetailsData && selectedItemDetailsData?.rate_difference)} * {selectedItemDetailsData?.allotted_qty} MTS)</span></div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹
                                                                                                {Number(getTotalDiscountedRate(selectedItemDetailsData && selectedItemDetailsData?.rate_difference) * (Number(selectedItemDetailsData?.allotted_qty) - Number(selectedItemDetailsData?.dispached_qty || 0))).toFixed(2)}</div>
                                                                                        </div>
                                                                                        <Divider variant="fullWidth" />
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Taxable Amount(Approx)</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹ {Number(selectedItemDetailsData?.totalTaxableAmt).toFixed(2)}</div>
                                                                                        </div>
                                                                                        {gstOption === "IGST" ?
                                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>{`IGST ${igstRate || 0} %`}</div>
                                                                                                <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>₹{" "}
                                                                                                    {(orderDetails?.rate) === 0
                                                                                                        ? 0
                                                                                                        : parseFloat(
                                                                                                            selectedItemDetailsData?.igst || 0
                                                                                                        ).toFixed(2)}
                                                                                                </div>
                                                                                            </div> :
                                                                                            <>
                                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}> {`SGST ${sgstRate || 0} %`}</div>
                                                                                                    <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>
                                                                                                        ₹{" "}
                                                                                                        {(orderDetails?.rate) === 0
                                                                                                            ? 0
                                                                                                            : parseFloat(
                                                                                                                selectedItemDetailsData?.sgst || 0
                                                                                                            ).toFixed(2)}</div>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>{`CGST ${cgstRate || 0} %`}</div>
                                                                                                    <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>
                                                                                                        ₹{" "}
                                                                                                        {(orderDetails?.rate) === 0
                                                                                                            ? 0
                                                                                                            : parseFloat(
                                                                                                                selectedItemDetailsData?.cgst || 0
                                                                                                            ).toFixed(2)}</div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}> TCS {" "}
                                                                                                {tcsRate === null ? "" : tcsRate?.tcs_rate}
                                                                                                {" "}
                                                                                                %</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>
                                                                                                {calculatePercent(tcsRate?.tcs_rate, (Number(selectedItemDetailsData?.totalTaxableAmt) + Number((selectedItemDetailsData?.totalAmt || 0)))).toFixed(3)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "3px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Cess Amount</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}>{subcontractData.cess_rate || 0.00}</div>
                                                                                        </div>
                                                                                        <Divider variant="fullWidth" />
                                                                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#3B69B0", fontWeight: 500 }}>Total Value</div>
                                                                                            <div style={{ fontSize: "11px", fontFamily: "Poppins", fontStyle: "italic", textAlign: "right", color: "#000000", fontWeight: 500 }}><b>₹
                                                                                                {Number(Number(selectedItemDetailsData?.totalTaxableAmt) + Number(selectedItemDetailsData?.totalAmt) +
                                                                                                    calculatePercent(tcsRate?.tcs_rate, (Number(selectedItemDetailsData?.totalTaxableAmt) + Number((selectedItemDetailsData?.totalAmt || 0)))) + Number(subcontractData.cess_rate || 0.00)).toFixed(2)}
                                                                                            </b></div>
                                                                                        </div>
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </Popover>
                                                                    </div>

                                                                }
                                                            </>
                                                        </Paper>
                                                    </ThemeProvider>
                                                    {(mode == "addSubContracts" || mode == "editSubContracts") ?
                                                        <>
                                                            <div style={{ paddingTop: "15px" }}>
                                                                <ToolTipComponent
                                                                    title="Subcontract available quantity should be greater than zero"
                                                                    condition={subContractAvailableQuantity.current <= 0}
                                                                >
                                                                    <Button
                                                                        size="medium"
                                                                        className={classes.addNewButton}
                                                                        variant="outlined"
                                                                        startIcon={<AddOutlinedIcon />}
                                                                        disabled={subContractAvailableQuantity.current <= 0 ? true : false}
                                                                        onClick={() => {
                                                                            setHasItemError(false)
                                                                            const items = [...itemDetailsData]
                                                                            items.push({
                                                                                item: null,
                                                                                item_code: "",
                                                                                allotted_qty: null,
                                                                                remark: "",
                                                                                total_value: 0.000,
                                                                                la_qty: 0.000,
                                                                                available_qty: 0.000,
                                                                                method: ADDED
                                                                            })
                                                                            setItemDetailsData(items)
                                                                        }}
                                                                    >
                                                                        ADD NEW
                                                                    </Button>
                                                                </ToolTipComponent>

                                                                <div style={{ color: "red" }}>
                                                                    {hasItemError && (
                                                                        <span>{itemErrorMessage}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/* Validation */}
                                                            <div style={{ display: "flex", fontSize: "small", fontFamily: "Poppins", padding: "3px" }}>
                                                                {isSubcontractQtyValid ?
                                                                    <div>
                                                                        <Check style={{ color: COLORS.lightGreen, fontSize: "small", marginTop: "2px" }} />
                                                                    </div> :
                                                                    <div>
                                                                        <Cancel style={{ color: COLORS.pink, fontSize: "small", marginTop: "2px" }} />
                                                                    </div>
                                                                }
                                                                <div style={{ color: isSubcontractQtyValid ? COLORS.lightGreen : COLORS.pink, marginLeft: "2px" }}>
                                                                    Subcontract Quantity cannot be more than available quantity in this plant
                                                                </div>
                                                            </div>
                                                            {
                                                                isVclEnabledRef.current &&
                                                                <div style={{ display: "flex", fontSize: "small", fontFamily: "Poppins", padding: "3px" }}>
                                                                    {isTotalValueValid ?
                                                                        <div>
                                                                            <Check style={{ color: COLORS.lightGreen, fontSize: "small", marginTop: "2px" }} />
                                                                        </div> :
                                                                        <div>
                                                                            <Cancel style={{ color: COLORS.pink, fontSize: "small", marginTop: "2px" }} />
                                                                        </div>
                                                                    }
                                                                    <div style={{ color: isTotalValueValid ? COLORS.lightGreen : COLORS.pink, marginLeft: "2px" }}>
                                                                        Total Value cannot be more than Payment Doc Value of this subcontract
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div style={{ display: "flex", fontSize: "small", fontFamily: "Poppins", padding: "3px" }}>
                                                                {isTotalItemValueValid ?
                                                                    <div>
                                                                        <Check style={{ color: COLORS.lightGreen, fontSize: "small", marginTop: "2px" }} />
                                                                    </div> :
                                                                    <div>
                                                                        <Cancel style={{ color: COLORS.pink, fontSize: "small", marginTop: "2px" }} />
                                                                    </div>
                                                                }
                                                                <div style={{ color: isTotalItemValueValid ? COLORS.lightGreen : COLORS.pink, marginLeft: "2px" }}>
                                                                    Total Allotted Quantity cannot be more than Subcontract Quantity
                                                                </div>
                                                            </div>

                                                            <div style={{ paddingTop: "30px" }}>
                                                                {/* {console.log(hasItemError, !isSubcontractQtyValid, (isVclEnabledRef.current && !isTotalValueValid), !isTotalItemValueValid,
                                                                    itemDetailsData.some(i => i.item == null || i.allotted_qty == null || i.allotted_qty <= 0 || !i.allotted_qty?.toString().match(/^\d*\.?\d{0,3}$/)), itemDetailsData.length == 0)} */}
                                                                <Button
                                                                    size="medium"
                                                                    className={classes.saveButton}
                                                                    variant="contained"
                                                                    startIcon={<CheckOutlinedIcon />}
                                                                    type="submit"
                                                                    onClick={handleItems}
                                                                    disabled={hasItemError || !isSubcontractQtyValid || (isVclEnabledRef.current && !isTotalValueValid) || !isTotalItemValueValid
                                                                        || itemDetailsData.some(i => i.item == null || i.allotted_qty == null || i.allotted_qty <= 0 || !i.allotted_qty?.toString().match(/^\d*\.?\d{0,3}$/)) || itemDetailsData.length == 0}
                                                                >
                                                                    {mode == 'editSubContracts' ? 'UPDATE' : 'SAVE'}
                                                                </Button>

                                                                <Button
                                                                    size="medium"
                                                                    className={classes.backButton}
                                                                    onClick={handleBack}
                                                                >
                                                                    BACK
                                                                </Button>
                                                                <Button
                                                                    size="medium"
                                                                    onClick={() =>
                                                                        props.history.goBack()}
                                                                >
                                                                    CANCEL
                                                                </Button>
                                                            </div>
                                                        </>
                                                        : <></>
                                                    }
                                                </>
                                            </StepContent>
                                        </Step>

                                    </Stepper>
                                </div>
                            </Paper>
                        </div>
                    )}

                </div>
            </div >
            <SnackbarComponent
                open={snackbarData.openSnackbar}
                handleClose={handleCloseSnackbar}
                snackbarMessage={snackbarData.snackbarMessage}
                type={snackbarData.snackbarType}
            />
        </Layout >
    );
}

